import {
  PaymentFormData,
  PaymentInstruction,
  PaymentInstructionReferencePrefixAndSuffixLength,
} from 'types/paymentInstruction'
import { convertAmountCentsToForm } from '.'
import { extractPrefixAndSuffixFromReference } from './extractPrefixAndSuffixFromReference'
import mapVcnProviderPropertiesToFrom from './mapVcnProviderPropertiesToForm'

export default function mapPaymentInstructionToForm(
  pi: PaymentInstruction,
  prefixAndSuffix: PaymentInstructionReferencePrefixAndSuffixLength
): PaymentFormData {
  const { beneficiaryId, reference, amount, currency, remittanceAdvice, source } = pi

  const referenceString = reference.join(', ')

  const referenceWithoutPrefixAndSuffix = extractPrefixAndSuffixFromReference(referenceString, prefixAndSuffix)

  const paymentFormData: PaymentFormData = {
    beneficiaryId,
    reference: referenceWithoutPrefixAndSuffix,
    amount: convertAmountCentsToForm(amount),
    currency,
    sendRemittance: (remittanceAdvice?.emails?.length || 0) > 0,
    remittanceAdvice: remittanceAdvice?.emails?.[0],
    recurring: false,
  }

  if (source && source.type === 'vcn' && source.typeProperties.vcnProviderProperties) {
    paymentFormData.vcnProviderProperties = mapVcnProviderPropertiesToFrom(source.typeProperties.vcnProviderProperties)
  }

  return paymentFormData
}
