import { defineMessages } from 'react-intl'

export const util = defineMessages({
  'util.payment.status.ready': {
    id: 'util.payment.status.ready',
    defaultMessage: 'READY',
    description: 'This string sets ready filter option in payment status',
  },
  'util.payment.status.signature.required': {
    id: 'util.payment.status.signature.required',
    defaultMessage: 'REQUIRE_SIGNATURE',
    description: 'This string sets signature required filter option in payment status',
  },
  'util.payment.status.pending': {
    id: 'util.payment.status.pending',
    defaultMessage: 'PENDING',
    description: 'This string sets pending filter option in payment status',
  },
  'util.payment.status.bank': {
    id: 'util.payment.status.bank',
    defaultMessage: 'BANK',
    description: 'This string sets bank filter option in payment status',
  },
  'util.payment.status.paid': {
    id: 'util.payment.status.paid',
    defaultMessage: 'PAID',
    description: 'This string sets paid filter option in payment status',
  },
  'util.payment.status.error': {
    id: 'util.payment.status.error',
    defaultMessage: 'ERROR',
    description: 'This string sets error filter option in payment status',
  },
  'util.payment.status.canceled': {
    id: 'util.payment.status.canceled',
    defaultMessage: 'CANCELED',
    description: 'This string sets cancelled filter option in payment status',
  },
  'util.report.fields.amount': {
    id: 'util.report.fields.amount',
    defaultMessage: 'Amount',
    description: 'This string sets amount filter option in report fields',
  },
  'util.report.fields.date.due': {
    id: 'util.report.fields.date.due',
    defaultMessage: 'Date due',
    description: 'This string sets date due filter option in report fields',
  },
  'util.report.fields.fee': {
    id: 'util.report.fields.fee',
    defaultMessage: 'Fee',
    description: 'This string sets fee filter option in report fields',
  },
  'util.report.fields.currency': {
    id: 'util.report.fields.currency',
    defaultMessage: 'Currency',
    description: 'This string sets currency filter option in report fields',
  },
  'util.report.fields.pi.state': {
    id: 'util.report.fields.pi.state',
    defaultMessage: 'PI state',
    description: 'This string sets pi state filter option in report fields',
  },
  'util.report.fields.reference': {
    id: 'util.report.fields.reference',
    defaultMessage: 'Reference',
    description: 'This string sets reference filter option in report fields',
  },
  'util.report.fields.creation.channel': {
    id: 'util.report.fields.creation.channel',
    defaultMessage: 'Creation channel',
    description: 'This string sets creation channel filter option in report fields',
  },
  'util.report.fields.fee.bearer': {
    id: 'util.report.fields.fee.bearer',
    defaultMessage: 'Fee bearer',
    description: 'This string sets fee bearer filter option in report fields',
  },
  'util.report.fields.date.card.charge': {
    id: 'util.report.fields.date.card.charge',
    defaultMessage: 'Date card charge',
    description: 'This string sets date card charge filter option in report fields',
  },
  'util.report.fields.card.card': {
    id: 'util.report.fields.card.card',
    defaultMessage: 'Card',
    description: 'This string sets card filter option in report fields',
  },
  'util.report.fields.account.number': {
    id: 'util.report.fields.account.number',
    defaultMessage: 'Account Number',
    description: 'This string sets account number filter option in report fields',
  },
  'util.report.fields.clearing.network': {
    id: 'util.report.fields.clearing.network',
    defaultMessage: 'Clearing network',
    description: 'This string sets clearing network filter option in report fields',
  },
  'util.report.fields.beneficiary.label': {
    id: 'util.report.fields.beneficiary.label',
    defaultMessage: 'Recipient',
    description: 'This string sets beneficiary label filter option in report fields',
  },
  'util.report.fields.beneficiary.country.code': {
    id: 'util.report.fields.beneficiary.country.code',
    defaultMessage: 'Recipient country code',
    description: 'This string sets beneficiary country code filter option in report fields',
  },
  'util.report.fields.pi.date.created': {
    id: 'util.report.fields.pi.date.created',
    defaultMessage: 'PI date created',
    description: 'This string sets pi date created filter option in report fields',
  },
  'util.report.fields.internal.company.number': {
    id: 'util.report.fields.internal.company.number',
    defaultMessage: 'Entity number',
    description: 'This string sets entity number field option in report fields',
  },
  'util.report.fields.entity': {
    id: 'util.report.fields.entity',
    defaultMessage: 'Payer legal entity',
    description: 'This string sets entity field option in report fields',
  },
  'util.report.fields.account.type': {
    id: 'util.report.fields.account.type',
    defaultMessage: 'Account type',
    description: 'This string sets entity field option in report fields',
  },
  'util.report.fields.routing.number': {
    id: 'util.report.fields.routing.number',
    defaultMessage: 'Sort code/BIC',
    description: 'This string sets routing number field option in report fields',
  },
  'util.report.fields.amount.on.card': {
    id: 'util.report.fields.amount.on.card',
    defaultMessage: 'Total amount on card',
    description: 'This string sets amount on card field option in report fields',
  },
  'util.report.fields.amount.paid.out': {
    id: 'util.report.fields.amount.paid.out',
    defaultMessage: 'Amount paid out',
    description: 'This string sets amount paid out field option in report fields',
  },
  'util.report.fields.card.network': {
    id: 'util.report.fields.card.network',
    defaultMessage: 'Card network',
    description: 'This string sets card network option in report fields',
  },
  'util.report.fields.supplier.funded': {
    id: 'util.report.fields.supplier.funded',
    defaultMessage: 'Supplier funded',
    description: 'This string sets supplier funded option in report fields',
  },
  'util.report.fields.user': {
    id: 'util.report.fields.user',
    defaultMessage: 'User',
    description: 'This string sets user option in report fields',
  },
  'util.report.fields.refunded': {
    id: 'util.report.fields.refunded',
    defaultMessage: 'Refunded',
    description: 'This string sets refunded option in report fields',
  },
  'util.report.fields.date.created': {
    id: 'util.report.fields.date.created',
    defaultMessage: 'Date created',
    description: 'This string sets date created option in report fields',
  },
  'util.report.fields.amount.usd': {
    id: 'util.report.fields.amount.usd',
    defaultMessage: 'Amount USD',
    description: 'This string sets amountUsd option in report fields',
  },
  'util.report.fields.amount.chf': {
    id: 'util.report.fields.amount.chf',
    defaultMessage: 'Amount CHF',
    description: 'This string sets amountChf option in report fields',
  },
  'util.report.fields.attachment': {
    id: 'util.report.fields.attachment',
    defaultMessage: 'Attachment',
    description: 'This string sets attachment option in report fields',
  },
  'util.report.fields.adder': {
    id: 'util.report.fields.adder',
    defaultMessage: 'Added by',
    description: 'This string sets adder option in report fields',
  },
  'util.report.fields.payer': {
    id: 'util.report.fields.payer',
    defaultMessage: 'Paid by',
    description: 'This string sets payer option in report fields',
  },
  'util.report.fields.include.dbis': {
    id: 'util.report.fields.include.dbis',
    defaultMessage: 'Include VCN DBIs',
    description: 'This string sets include dbis option in report fields',
  },
  'util.date.options.last.thirty.days': {
    id: 'util.date.options.last.thirty.days',
    defaultMessage: 'Last 30 days',
    description: 'This string sets last 30 days filter option in date options in reports',
  },
  'util.date.options.this.year': {
    id: 'util.date.options.this.year',
    defaultMessage: 'This year',
    description: 'This string sets this year filter option in date options in reports',
  },
  'util.date.options.specific.date.range': {
    id: 'util.date.options.specific.date.range',
    defaultMessage: 'Specific date range',
    description: 'This string sets specific date range filter option in date options in reports',
  },
  'util.beneficiary.type.personal': {
    id: 'util.beneficiary.type.personal',
    defaultMessage: 'personal',
    description: 'This string sets personal type in beneficiary table',
  },
  'util.beneficiary.type.business': {
    id: 'util.beneficiary.type.business',
    defaultMessage: 'business',
    description: 'This string sets business type in beneficiary table',
  },
})
