import {
  PaymentFormData,
  PaymentInstructionTemplate,
  PaymentInstructionReferencePrefixAndSuffixLength,
} from 'types/paymentInstruction'
import { convertAmountCentsToForm } from '.'
import { extractPrefixAndSuffixFromReference } from './extractPrefixAndSuffixFromReference'
import mapVcnProviderPropertiesToFrom from './mapVcnProviderPropertiesToForm'

export default function mapPaymentInstructionTemplateToForm(
  template: PaymentInstructionTemplate,
  prefixAndSuffix: PaymentInstructionReferencePrefixAndSuffixLength
): PaymentFormData {
  const { occurrencesRemaining, paymentInstructionProperties } = template
  const { sourceId, beneficiaryId, reference, amount, currency, remittanceAdvice, source } =
    paymentInstructionProperties

  const referenceString = reference.join(', ')

  const referenceWithoutPrefixAndSuffix = extractPrefixAndSuffixFromReference(referenceString, prefixAndSuffix)

  const paymentFormData: PaymentFormData = {
    sourceId,
    beneficiaryId,
    reference: referenceWithoutPrefixAndSuffix,
    amount: convertAmountCentsToForm(amount),
    currency,
    sendRemittance: !!remittanceAdvice,
    remittanceAdvice: remittanceAdvice ? remittanceAdvice.emails[0] : undefined,
    recurring: occurrencesRemaining && occurrencesRemaining === 1 ? false : true,
  }

  if (source && source.type === 'vcn' && source.typeProperties.vcnProviderProperties) {
    paymentFormData.vcnProviderProperties = mapVcnProviderPropertiesToFrom(source.typeProperties.vcnProviderProperties)
  }

  return paymentFormData
}
