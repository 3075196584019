import { useSession } from 'stores/session'
import { AirplusIntegration } from 'types/rules'

export default function useActiveIntegration() {
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!

  const vcnIntegrations: AirplusIntegration[] =
    (rules.logic.activeIntegrations.rule.vcnAirplus as AirplusIntegration[]) || []
  const isVcnIntegrationActive = vcnIntegrations.some((r) => r.status.toLowerCase() === 'active')

  return {
    isVcnIntegrationActive,
    vcnIntegrations,
  }
}
