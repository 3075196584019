import { defineMessages } from 'react-intl'

export const pi = defineMessages({
  'pi.state': {
    id: 'pi.state',
    defaultMessage: 'State',
    description: 'State of the PI',
  },
  'pi.status': {
    id: 'pi.status',
    defaultMessage: 'Status',
    description: 'Status of the PI',
  },
  'pi.currency': {
    id: 'pi.currency',
    defaultMessage: 'Currency',
    description: 'Currency of the PI',
  },
  'pi.amount': {
    id: 'pi.amount',
    defaultMessage: 'Amount',
    description: 'Amount of the PI',
  },
  'pi.fee': {
    id: 'pi.fee',
    defaultMessage: 'Fee',
    description: 'Fee of the PI',
  },
  'pi.total': {
    id: 'pi.total',
    defaultMessage: 'Total Bill',
    description: 'Total amount',
  },
  'pi.connection': {
    id: 'pi.connection',
    defaultMessage: 'Connection',
    description: 'Connection',
  },
  'pi.actions': {
    id: 'pi.actions',
    defaultMessage: 'Actions',
    description: 'Actions of the PI',
  },
  'pi.reference': {
    id: 'pi.reference',
    defaultMessage: 'Reference',
    description: 'Reference for the beneficiary',
  },
  'pi.entity': {
    id: 'pi.entity',
    defaultMessage: 'Entity',
    description: 'Entity PI belong to',
  },
  'pi.dateCreated': {
    id: 'pi.dateCreated',
    defaultMessage: 'Date created',
    description: 'Date when PI was created',
  },
  'pi.dateDue': {
    id: 'pi.dateDue',
    defaultMessage: 'Date due',
    description: 'Date when PI is due',
  },
  'pi.dateUpdated': {
    id: 'pi.dateUpdated',
    defaultMessage: 'Date updated',
    description: 'Date when PI was updated',
  },
  'pi.occurrencesRemaining': {
    id: 'pi.occurrencesRemaining',
    defaultMessage: 'Remaining occurrences',
    description: 'How many occurrences remain for PI',
  },
  'pi.infiniteOcurrencesRemaining': {
    id: 'pi.infiniteOcurrencesRemaining',
    defaultMessage: 'Unlimited number of occurrences',
    description: 'Is number of PI occurrences unlimited',
  },
  'pi.infiniteOcurrencesRemainingOptionYes': {
    id: 'pi.infiniteOcurrencesRemainingOptionYes',
    defaultMessage: 'Yes',
    description: 'Is number of PI occurrences unlimited option yes',
  },
  'pi.paymentsFrequency': {
    id: 'pi.paymentsFrequency',
    defaultMessage: 'Frequency of payments',
    description: 'Frequency of payments',
  },
  'pi.paymentsFrequency.option.everyWeek': {
    id: 'pi.paymentsFrequency.option.everyWeek',
    defaultMessage: 'Every week',
    description: 'Frequency of payments option',
  },
  'pi.paymentsFrequency.option.every2Weeks': {
    id: 'pi.paymentsFrequency.option.every2Weeks',
    defaultMessage: 'Every 2 weeks',
    description: 'Frequency of payments option',
  },
  'pi.paymentsFrequency.option.everyMonth': {
    id: 'pi.paymentsFrequency.option.everyMonth',
    defaultMessage: 'Every month',
    description: 'Frequency of payments option',
  },
  'pi.paymentsFrequency.option.every2Months': {
    id: 'pi.paymentsFrequency.option.every2Months',
    defaultMessage: 'Every 2 months',
    description: 'Frequency of payments option',
  },
  'pi.beneficiary.title': {
    id: 'pi.beneficiary.title',
    defaultMessage: 'Beneficiary',
    description: 'This string sets beneficairay title',
  },
  'pi.beneficiary.routingNumber': {
    id: 'pi.beneficiary.routingNumber',
    defaultMessage: 'Beneficiary routing number',
    description: 'This string sets beneficiary routing number label',
  },
  'pi.beneficiary.accountNumber': {
    id: 'pi.beneficiary.accountNumber',
    defaultMessage: 'Beneficiary account number',
    description: 'This string sets beneficiary account number label',
  },
  'pi.state.actionRequired': {
    id: 'pi.state.actionRequired',
    defaultMessage: 'Action Required',
    description: 'PI state label',
  },
  'pi.state.all': {
    id: 'pi.state.all',
    defaultMessage: 'All',
    description: 'PI state label',
  },
  'pi.state.ongoing': {
    id: 'pi.state.ongoing',
    defaultMessage: 'Processing',
    description: 'PI state label',
  },
  'pi.state.complete': {
    id: 'pi.state.complete',
    defaultMessage: 'Complete',
    description: 'PI state label',
  },
  'pi.state.inReview': {
    id: 'pi.state.inReview',
    defaultMessage: 'In review',
    description: 'PI state label',
  },
  'pi.state.cancel': {
    id: 'pi.state.cancel',
    defaultMessage: 'Canceled',
    description: 'PI state label',
  },
  'pi.state.future': {
    id: 'pi.state.future',
    defaultMessage: 'Future',
    description: 'PI state label',
  },
  'pi.vcnProviderProperties': {
    id: 'pi.vcnProviderProperties',
    defaultMessage: 'VCN provider fields',
    description: 'VCN provider fields header',
  },
  'pi.add.addPayments.tabs.input': {
    id: 'pi.add.addPayments.tabs.input',
    defaultMessage: 'Input',
    description: 'Input tab label',
  },
  'pi.add.addPayments.tabs.import': {
    id: 'pi.add.addPayments.tabs.import',
    defaultMessage: 'File import',
    description: 'File import tab label',
  },
  'pi.add.addPayments.form.create.label': {
    id: 'pi.add.addPayments.form.create.label',
    defaultMessage: 'Add a payment',
    description: 'Create new payment form header',
  },
  'pi.add.addPayments.form.update.label': {
    id: 'pi.add.addPayments.form.update.label',
    defaultMessage: 'Update a Payment',
    description: 'Update a payment form header',
  },
  'pi.add.addPayments.form.header.card': {
    id: 'pi.add.addPayments.form.header.card',
    defaultMessage: 'Select card',
    description: 'Add payment form section header label',
  },
  'pi.add.addPayments.form.card.deatils.errorMessage.required': {
    id: 'pi.add.addPayments.form.card.details.errorMessage.required',
    defaultMessage: 'Please add card details',
    description: 'Card details required error message',
  },
  'pi.add.addPayments.form.header.recepient': {
    id: 'pi.add.addPayments.form.header.recepient',
    defaultMessage: 'Select recepient',
    description: 'Add payment form section header label',
  },
  'pi.add.addPayments.form.header.amountAndDate': {
    id: 'pi.add.addPayments.form.header.amountAndDate',
    defaultMessage: 'Amount & Date',
    description: 'Add payment form section header label',
  },
  'pi.add.addPayments.form.header.invoice.optional': {
    id: 'pi.add.addPayments.form.header.invoice.optional',
    defaultMessage: 'Invoice (optional)',
    description: 'Add payment form section header label',
  },
  'pi.add.addPayments.form.header.invoice': {
    id: 'pi.add.addPayments.form.header.invoice',
    defaultMessage: 'Invoice',
    description: 'Add payment form section header label',
  },
  'pi.add.addPayments.form.header.recurringPayment': {
    id: 'pi.add.addPayments.form.header.recurringPayment',
    defaultMessage: 'Recurring payment',
    description: 'Add payment form section header label',
  },
  'pi.add.addPayments.form.card.select.label': {
    id: 'pi.add.addPayments.form.card.select.label',
    defaultMessage: 'Choose Card',
    description: 'Card select label',
  },
  'pi.add.addPayments.form.card.select.placeholder': {
    id: 'pi.add.addPayments.form.card.select.placeholder',
    defaultMessage: 'Choose a card from saved cards',
    description: 'Card select placeholder',
  },
  'pi.add.addPayments.form.card.errorMessage.required': {
    id: 'pi.add.addPayments.form.card.errorMessage.required',
    defaultMessage: 'Please choose card',
    description: 'Card required error message',
  },
  'pi.add.addPayments.form.card.new': {
    id: 'pi.add.addPayments.form.card.new',
    defaultMessage: 'New Card',
    description: 'New card button label',
  },
  'pi.add.addPayments.form.card.no.card.available.label': {
    id: 'pi.add.addPayments.form.card.no.card.available.label',
    defaultMessage: 'Add a new card to proceed with the payment.',
    description: 'No card available label',
  },
  'pi.add.addPayments.form.card.changeCard': {
    id: 'pi.add.addPayments.form.card.changeCard',
    defaultMessage: 'Change card',
    description: 'This string sets change card button label',
  },
  'pi.add.addPayments.form.beneficiary.changeRecipient': {
    id: 'pi.add.addPayments.form.beneficiary.changeRecipient',
    defaultMessage: 'Change recipient',
    description: 'This string sets change recipient button label',
  },
  'pi.add.addPayments.form.beneficiary.blocked': {
    id: 'pi.add.addPayments.form.beneficiary.blocked',
    defaultMessage:
      'The account number {accountNumber} is blocked on Billhop. Payments cannot be made to this recipient',
    description: 'This string sets warning message when a beneficiary is on a blacklist',
  },
  'pi.add.addPayments.form.beneficiary.cancel': {
    id: 'pi.add.addPayments.form.beneficiary.cancel',
    defaultMessage: 'Cancel add beneficiary',
    description: 'This string sets cancel add recipient button label',
  },
  'pi.add.addPayments.form.beneficiary.select.label': {
    id: 'pi.add.addPayments.form.beneficiary.select.label',
    defaultMessage: 'Choose recepient',
    description: 'Beneficiary select label',
  },
  'pi.add.addPayments.form.beneficiary.select.placeholder': {
    id: 'pi.add.addPayments.form.beneficiary.select.placeholder',
    defaultMessage: 'Choose a recepient from saved recepients',
    description: 'Beneficiary select placeholder',
  },
  'pi.add.addPayments.form.beneficiary.errorMessage.required': {
    id: 'pi.add.addPayments.form.beneficiary.errorMessage.required',
    defaultMessage: 'Please choose recepient',
    description: 'Beneficiary required error message',
  },
  'pi.add.addPayments.form.beneficiary.new': {
    id: 'pi.add.addPayments.form.beneficiary.new',
    defaultMessage: 'New Recepient',
    description: 'New beneficiary button label',
  },
  'pi.add.addPayments.form.reference.label': {
    id: 'pi.add.addPayments.form.reference.label',
    defaultMessage: 'Reference for recepient',
    description: 'Reference for recepient label',
  },
  'pi.add.addPayments.form.reference.placeholder': {
    id: 'pi.add.addPayments.form.reference.placeholder',
    defaultMessage: 'Enter reference',
    description: 'Reference for recepient placeholder',
  },
  'pi.add.addPayments.form.reference.errorMessage.invalid.character': {
    id: 'pi.add.addPayments.form.reference.errorMessage.invalid.character',
    defaultMessage: 'Invalid character input',
    description:
      "Character input is not one of the allowed characters.a-z, digits from 0-9, characters as / - ? : ( ) . , ' + and space",
  },
  'pi.add.addPayments.form.reference.errorMessage.required': {
    id: 'pi.add.addPayments.form.reference.errorMessage.required',
    defaultMessage: 'Please enter reference',
    description: 'Reference for recepient required error message',
  },
  'pi.add.addPayments.form.cardDetails.error.message': {
    id: 'pi.add.addPayments.form.cardDetails.error.message',
    defaultMessage: 'Please add card details',
    description: 'Card details required error message',
  },
  'pi.add.addPayments.form.sendRemittance.label': {
    id: 'pi.add.addPayments.form.sendRemittance.label',
    defaultMessage: 'Send remittance advice to recipient',
    description: 'Send remittance advice to recipient label',
  },
  'pi.add.addPayments.form.sendRemittance.label.info': {
    id: 'pi.add.addPayments.form.sendRemittance.label.info',
    defaultMessage:
      'You can add multiple emails here seperated by comma.The confirmation email will have the details of the payer, recipient and the total amount',
    description: 'Send remittance advice to recipient label info popup text',
  },
  'pi.add.addPayments.form.sendRemittance.option.yes': {
    id: 'pi.add.addPayments.form.sendRemittance.option.yes',
    defaultMessage: 'Yes',
    description: 'Send remittance option Yes',
  },
  'pi.add.addPayments.form.sendRemittance.option.no': {
    id: 'pi.add.addPayments.form.sendRemittance.option.no',
    defaultMessage: 'No',
    description: 'Send remittance option No',
  },
  'pi.add.addPayments.form.remittanceAdvice.label': {
    id: 'pi.add.addPayments.form.remittanceAdvice.label',
    defaultMessage: 'Recepient email',
    description: 'Recepient email label',
  },
  'pi.add.addPayments.form.remittanceAdvice.placeholder': {
    id: 'pi.add.addPayments.form.remittanceAdvice.placeholder',
    defaultMessage: 'Enter recepient email addresses seperated by comma',
    description: 'Remittance advice placeholder',
  },
  'pi.add.addPayments.form.remittanceAdvice.errorMessage.required': {
    id: 'pi.add.addPayments.form.remittanceAdvice.errorMessage.required',
    defaultMessage: 'Please enter recepient email',
    description: 'Remittance advice required error message',
  },
  'pi.add.addPayments.form.remittanceAdvice.errorMessage.email': {
    id: 'pi.add.addPayments.form.remittanceAdvice.errorMessage.email',
    defaultMessage: 'The input is not valid E-mail',
    description: 'Remittance advice wrong email format message',
  },
  'pi.add.addPayments.form.amount.label': {
    id: 'pi.add.addPayments.form.amount.label',
    defaultMessage: 'Amount',
    description: 'Amount label',
  },
  'pi.add.addPayments.form.amount.placeholder': {
    id: 'pi.add.addPayments.form.amount.placeholder',
    defaultMessage: 'Enter amount',
    description: 'Amount placeholder',
  },
  'pi.add.addPayments.form.remittanceAdvice.email.validation.errorMessage': {
    id: 'pi.add.addPayments.form.remittanceAdvice.email.validation.errorMessage',
    defaultMessage: 'Please enter valid email',
    description: 'Email validation error message',
  },
  'pi.add.addPayments.form.amount.errorMessage.required': {
    id: 'pi.add.addPayments.form.amount.errorMessage.required',
    defaultMessage: 'Please enter amount',
    description: 'Amount required error message',
  },
  'pi.add.addPayments.form.amount.errorMessage.notValid': {
    id: 'pi.add.addPayments.form.amount.errorMessage.notValid',
    defaultMessage: 'The value entered is not allowed',
    description: 'Amount not valid error message',
  },
  'pi.add.addPayments.form.currency.label': {
    id: 'pi.add.addPayments.form.currency.label',
    defaultMessage: 'Currency',
    description: 'Currency label',
  },
  'pi.add.addPayments.form.currency.errorMessage.required': {
    id: 'pi.add.addPayments.form.currency.errorMessage.required',
    defaultMessage: 'Please choose currency',
    description: 'Currency required error message',
  },
  'pi.add.addPayments.form.attachment.errorMessage.required': {
    id: 'pi.add.addPayments.form.attachment.errorMessage.required',
    defaultMessage: 'Please attach invoice',
    description: 'Attachment required error message',
  },
  'pi.add.addPayments.form.dateDue.label': {
    id: 'pi.add.addPayments.form.dateDue.label',
    defaultMessage: 'Due date',
    description: 'Date due label',
  },
  'pi.add.addPayments.form.dateDue.label.info': {
    id: 'pi.add.addPayments.form.dateDue.label.info',
    defaultMessage: 'This is the earliest payment date which the customer can select',
    description: 'Date due label info text',
  },
  'pi.add.addPayments.form.dateDue.change': {
    id: 'pi.add.addPayments.form.dateDue.change',
    defaultMessage: 'Change',
    description: 'Change date due button label',
  },
  'pi.add.addPayments.form.attachInvoice.label': {
    id: 'pi.add.addPayments.form.attachInvoice.label',
    defaultMessage: 'Attach invoice copy',
    description: 'Attach invoice copy label',
  },
  'pi.add.addPayments.form.attachInvoice.text': {
    id: 'pi.add.addPayments.form.attachInvoice.text',
    defaultMessage: 'This will be available for you and will not be sent to the recepient.',
    description: 'Attach invoice copy text',
  },
  'pi.add.addPayments.form.attachInvoice.upload': {
    id: 'pi.add.addPayments.form.attachInvoice.upload',
    defaultMessage: 'Upload',
    description: 'Attach invoice button label',
  },
  'pi.add.addPayments.form.recurring.label': {
    id: 'pi.add.addPayments.form.recurring.label',
    defaultMessage: 'Recurring payment?',
    description: 'Recurring payment label',
  },
  'pi.add.addPayments.file.import.success': {
    id: 'pi.add.addPayments.file.import.success',
    defaultMessage: 'File Uploaded successfully',
    description: 'File import success message',
  },
  'pi.add.addPayments.file.import.fail': {
    id: 'pi.add.addPayments.file.import.fail',
    defaultMessage: 'Error occured while uploading.',
    description: 'File import fail message',
  },
  'pi.add.addPayments.file.import.partialSuccess': {
    id: 'pi.add.addPayments.file.import.partialSuccess',
    defaultMessage: 'File upload partially successfull',
    description: 'File import partial success message',
  },
  'pi.add.addPayments.form.recurring.option.yes': {
    id: 'pi.add.addPayments.form.recurring.option.yes',
    defaultMessage: 'Yes',
    description: 'Recurring payment option yes',
  },
  'pi.add.addPayments.form.recurring.option.no': {
    id: 'pi.add.addPayments.form.recurring.option.no',
    defaultMessage: 'No',
    description: 'Recurring payment option no',
  },
  'pi.add.addPayments.form.recurring.change': {
    id: 'pi.add.addPayments.form.recurring.change',
    defaultMessage: 'Change',
    description: 'Change recurring payment button label',
  },
  'pi.add.addPayments.form.recurring.occurrencesRemaining': {
    id: 'pi.add.addPayments.form.recurring.occurrencesRemaining',
    defaultMessage: '{occurrencesRemaining} times',
    description: 'How many occurrences remain for PI',
  },
  'pi.add.addPayments.form.button.update.cancel': {
    id: 'pi.add.addPayments.form.button.update.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel payment update button label',
  },
  'pi.add.addPayments.form.button.update': {
    id: 'pi.add.addPayments.form.button.update',
    defaultMessage: 'Update',
    description: 'Update payment button label',
  },
  'pi.add.addPayments.form.button.add': {
    id: 'pi.add.addPayments.form.button.add',
    defaultMessage: 'Add',
    description: 'Add payment button label',
  },
  'pi.add.addPayments.action.addCard': {
    id: 'pi.add.addPayments.action.addCard',
    defaultMessage: 'Add Card',
    description: 'Add card action page header',
  },
  'pi.add.addPayments.action.addBeneficiary': {
    id: 'pi.add.addPayments.action.addBeneficiary',
    defaultMessage: 'Add Beneficiary',
    description: 'Add beneficiary action page header',
  },
  'pi.add.addPayments.action.selectDateDue': {
    id: 'pi.add.addPayments.action.selectDateDue',
    defaultMessage: 'Select Due Date',
    description: 'Select Due Date action page header',
  },
  'pi.add.addPayments.action.uploadInvoice': {
    id: 'pi.add.addPayments.action.uploadInvoice',
    defaultMessage: 'Upload invoice',
    description: 'Invoice upload drawer heading',
  },
  'pi.add.addPayments.action.recurrence': {
    id: 'pi.add.addPayments.action.recurrence',
    defaultMessage: 'Recurrence',
    description: 'Recurrence action page header',
  },
  'pi.add.addPayments.dateDueForm.update': {
    id: 'pi.add.addPayments.dateDueForm.update',
    defaultMessage: 'Update',
    description: 'Update date due button label',
  },
  'pi.add.addPayments.recurrenceForm.limit.label': {
    id: 'pi.add.addPayments.recurrenceForm.limit.label',
    defaultMessage: 'Specify number of occurences',
    description: 'Recurrence limit label',
  },
  'pi.add.addPayments.recurrenceForm.limit.option.noLimit': {
    id: 'pi.add.addPayments.recurrenceForm.limit.option.noLimit',
    defaultMessage: 'No Limit',
    description: 'Recurrence no limit option',
  },
  'pi.add.addPayments.recurrenceForm.limit.option.limit': {
    id: 'pi.add.addPayments.recurrenceForm.limit.option.limit',
    defaultMessage: 'Limit',
    description: 'Recurrence limit option',
  },
  'pi.add.addPayments.recurrenceForm.occurrencesRemaining.label': {
    id: 'pi.add.addPayments.recurrenceForm.occurrencesRemaining.label',
    defaultMessage: 'Number of occurences',
    description: 'Occurrences remaining label',
  },
  'pi.add.addPayments.recurrenceForm.occurrencesRemaining.placeholder': {
    id: 'pi.add.addPayments.recurrenceForm.occurrencesRemaining.placeholder',
    defaultMessage: 'Enter occurences',
    description: 'Occurrences remaining placeholder',
  },
  'pi.add.addPayments.recurrenceForm.occurrencesRemaining.errorMessage.required': {
    id: 'pi.add.addPayments.recurrenceForm.occurrencesRemaining.errorMessage.required',
    defaultMessage: 'Please enter number of occurences',
    description: 'Occurrences remaining required error message',
  },
  'pi.add.addPayments.recurrenceForm.interval.label': {
    id: 'pi.add.addPayments.recurrenceForm.interval.label',
    defaultMessage: 'Frequency of payments',
    description: 'Frequency of payments label',
  },
  'pi.add.addPayments.recurrenceForm.interval.placeholder': {
    id: 'pi.add.addPayments.recurrenceForm.interval.placeholder',
    defaultMessage: 'Select Frequency',
    description: 'Frequency of payments placeholder',
  },
  'pi.add.addPayments.recurrenceForm.interval.errorMessage.required': {
    id: 'pi.add.addPayments.recurrenceForm.interval.errorMessage.required',
    defaultMessage: 'Please select frequency of payments',
    description: 'Frequency of payments required error message',
  },
  'pi.add.addPayments.recurrenceForm.interval.option.everyWeek': {
    id: 'pi.add.addPayments.recurrenceForm.interval.option.everyWeek',
    defaultMessage: 'Please select frequency of payments',
    description: 'Frequency of payments required error message',
  },
  'pi.add.addPayments.recurrenceForm.update': {
    id: 'pi.add.addPayments.recurrenceForm.update',
    defaultMessage: 'Update',
    description: 'Recurrence form submit button label',
  },
  'pi.add.reviewPayments.labele': {
    id: 'pi.add.reviewPayments.labele',
    defaultMessage: 'Select all added bills',
    description: 'Review payments label',
  },
  'pi.add.reviewPayments.selectPayments.option.all': {
    id: 'pi.add.reviewPayments.selectPayments.option.all',
    defaultMessage: 'All',
    description: 'Select all payments button label',
  },
  'pi.add.reviewPayments.selectPayments.option.none': {
    id: 'pi.add.reviewPayments.selectPayments.option.none',
    defaultMessage: 'None',
    description: 'Deselect all payments button label',
  },
  'pi.add.reviewPayments.noData.label': {
    id: 'pi.add.reviewPayments.noData.label',
    defaultMessage: 'Payments will be displayed here after you add them',
    description: 'No data label',
  },
  'pi.add.reviewPayments.noPaymentSelected.label': {
    id: 'pi.add.reviewPayments.noDanoPaymentSelectedta.label',
    defaultMessage: 'Please select payments',
    description: 'No payment selected label',
  },
  'pi.add.reviewPayments.filter.pendingApprovals.placeholder': {
    id: 'pi.add.reviewPayments.filter.pendingApprovals.placeholder',
    defaultMessage: 'Pending approvals',
    description: 'Pending approvals filter placeholder',
  },
  'pi.add.reviewPayments.filter.pendingApprovals.option.all': {
    id: 'pi.add.reviewPayments.filter.pendingApprovals.option.all',
    defaultMessage: 'All',
    description: 'Pending approvals filter option',
  },
  'pi.add.reviewPayments.filter.pendingApprovals.option.one': {
    id: 'pi.add.reviewPayments.filter.pendingApprovals.option.one',
    defaultMessage: 'One',
    description: 'Pending approvals filter option',
  },
  'pi.add.reviewPayments.filter.pendingApprovals.option.multiple': {
    id: 'pi.add.reviewPayments.filter.pendingApprovals.option.multiple',
    defaultMessage: 'Multiple',
    description: 'Pending approvals filter option',
  },
  'pi.add.reviewPayments.filter.state.placeholder': {
    id: 'pi.add.reviewPayments.filter.state.placeholder',
    defaultMessage: 'State',
    description: 'State filter placeholder',
  },
  'pi.add.reviewPayments.filter.state.option.all': {
    id: 'pi.add.reviewPayments.filter.state.option.all',
    defaultMessage: 'All',
    description: 'State filter option',
  },
  'pi.add.reviewPayments.filter.state.option.assigned': {
    id: 'pi.add.reviewPayments.filter.state.option.assigned',
    defaultMessage: 'Assigned',
    description: 'State filter option',
  },
  'pi.add.reviewPayments.filter.state.option.unassigned': {
    id: 'pi.add.reviewPayments.filter.state.option.unassigned',
    defaultMessage: 'Unassigned',
    description: 'State filter option',
  },
  'pi.add.reviewPayments.filter.state.option.payable': {
    id: 'pi.add.reviewPayments.filter.state.option.payable',
    defaultMessage: 'Payable',
    description: 'State filter option',
  },
  'pi.add.reviewPayments.filter.state.option.error': {
    id: 'pi.add.reviewPayments.filter.state.option.error',
    defaultMessage: 'Error',
    description: 'State filter option',
  },
  'pi.add.reviewPayments.filter.currency.placeholder': {
    id: 'pi.add.reviewPayments.filter.currency.placeholder',
    defaultMessage: 'Currency',
    description: 'Currency filter placeholder',
  },
  'pi.add.reviewPayments.filter.card.placeholder': {
    id: 'pi.add.reviewPayments.filter.card.placeholder',
    defaultMessage: 'Card',
    description: 'Card filter placeholder',
  },
  'pi.add.reviewPayments.changeCard.button': {
    id: 'pi.add.reviewPayments.changeCard.button',
    defaultMessage: 'Change',
    description: 'Change card button label',
  },
  'pi.add.reviewPayments.addCard.button': {
    id: 'pi.add.reviewPayments.addCard.button',
    defaultMessage: 'Add',
    description: 'Add card button label',
  },
  'pi.add.reviewPayments.payment.state.ready': {
    id: 'pi.add.reviewPayments.payment.state.ready',
    defaultMessage: 'Ready for payment',
    description: 'Payment in ready state',
  },
  'pi.add.reviewPayments.payment.state.requiredSignature': {
    id: 'pi.add.reviewPayments.payment.state.requiredSignature',
    defaultMessage: 'Signature required before payment',
    description: 'Payment in require signature state',
  },
  'pi.add.reviewPayments.payment.state.requiredSignature.requestSentTo': {
    id: 'pi.add.reviewPayments.payment.state.requiredSignature.requestSentTo',
    defaultMessage: 'Signature required',
    description: 'Request for signature is sent to',
  },
  'pi.add.reviewPayments.paymentSummary.header.pi': {
    id: 'pi.add.reviewPayments.paymentSummary.header.pi',
    defaultMessage:
      '{numberOfPayments} {numberOfPayments, plural, one {payment is} other {payments are}} ready to be paid',
    description: 'Payments summary header',
  },
  'pi.add.reviewPayments.paymentSummary.header.template': {
    id: 'pi.add.reviewPayments.paymentSummary.header.template',
    defaultMessage:
      '{numberOfPayments} {numberOfPayments, plural, one {payment is} other {payments are}} ready to be paid',
    description: 'Payments summary header',
  },
  'pi.add.reviewPayments.paymentSummary.numberOfPayments': {
    id: 'pi.add.reviewPayments.paymentSummary.numberOfPayments',
    defaultMessage: '{numberOfPayments} {numberOfPayments, plural, one {payment} other {payments}}',
    description: 'Number of payments',
  },
  'pi.add.reviewPayments.paymentSummary.serviceFee': {
    id: 'pi.add.reviewPayments.paymentSummary.serviceFee',
    defaultMessage: 'Service fee',
    description: 'Service fee for payments',
  },
  'pi.add.reviewPayments.paymentSummary.original.fee': {
    id: 'pi.add.reviewPayments.paymentSummary.original.fee',
    defaultMessage: 'Original fee',
    description: 'Original fee label',
  },
  'pi.add.reviewPayments.paymentSummary.total': {
    id: 'pi.add.reviewPayments.paymentSummary.total',
    defaultMessage: 'Total to pay',
    description: 'Total to pay for payments',
  },
  'pi.add.reviewPayments.approvers.header': {
    id: 'pi.add.reviewPayments.approvers.header',
    defaultMessage: '{numberOfPayments} {numberOfPayments, plural, one {payment needs} other {payments need}} approval',
    description: 'Approvers header',
  },
  'pi.add.reviewPayments.approvers.message': {
    id: 'pi.add.reviewPayments.approvers.message',
    defaultMessage:
      'You account setting require dual approval for payments by {numberOfSignaturesRequired} {numberOfSignaturesRequired, plural, one {user} other {users}}. Please select the users below and then submit for approval. They will be notified by email for pending payments.',
    description: 'Approvers message',
  },
  'pi.add.reviewPayments.approvers.sequential.message': {
    id: 'pi.add.reviewPayments.approvers.sequential.message',
    defaultMessage:
      'Your account settings requires {numberOfSignaturesRequired} {numberOfSignaturesRequired, plural, one {round} other {rounds}} rounds of approvals. Please select from the users below for the {round}. approval round.',
    description: 'Approvers message',
  },
  'pi.add.reviewPayments.approvers.available.approvers': {
    id: 'pi.add.reviewPayments.approvers.available.approvers',
    defaultMessage: 'Available approvers',
    description: 'Approvers list header',
  },
  'pi.add.reviewPayments.approvers.list.header': {
    id: 'pi.add.reviewPayments.approvers.list.header',
    defaultMessage: 'Available users',
    description: 'Approvers list header',
  },
  'pi.add.reviewPayments.approvers.list.selectOption.all': {
    id: 'pi.add.reviewPayments.approvers.list.selectOption.all',
    defaultMessage: 'All',
    description: 'Approvers list select option all label',
  },
  'pi.add.reviewPayments.approvers.list.selectOption.none': {
    id: 'pi.add.reviewPayments.approvers.list.selectOption.none',
    defaultMessage: 'None',
    description: 'Approvers list select option none label',
  },
  'pi.add.reviewPayments.required.approvers': {
    id: 'pi.add.reviewPayments.required.approvers',
    defaultMessage: 'Required approvers',
    description: 'Required approvers label',
  },
  'pi.add.reviewPayments.submit.numberToPay': {
    id: 'pi.add.reviewPayments.submit.numberToPay',
    defaultMessage: 'Make {numberOfPaymentsToPay} {numberOfPaymentsToPay, plural, one {payment} other {payments}}',
    description: 'Submit button label',
  },
  'pi.add.reviewPayments.submit.numberForRequestSignature': {
    id: 'pi.add.reviewPayments.submit.numberForRequestSignature',
    defaultMessage:
      'Submit {numberOfPaymentsForSignatureRequest} {numberOfPaymentsForSignatureRequest, plural, one {payment} other {payments}} for approval',
    description: 'Submit button label',
  },
  'pi.add.reviewPayments.submit.numberOfPaymentsToApprove': {
    id: 'pi.add.reviewPayments.submit.numberOfPaymentsToApprove',
    defaultMessage:
      'Approve {numberOfPaymentsToApprove} {numberOfPaymentsToApprove, plural, one {payment} other {payments}}',
    description: 'Submit button label',
  },
  'pi.add.reviewPayments.submit.numberOfPaymentsToApproveAndRequest': {
    id: 'pi.add.reviewPayments.submit.numberOfPaymentsToApproveAndRequest',
    defaultMessage:
      'Approve {numberOfPaymentsToApprove} {numberOfPaymentsToApprove, plural, one {payment} other {payments}} and request {numberOfSignatureRequests} {numberOfSignatureRequests, plural, one {signature} other {signatures}}',
    description: 'Submit button label',
  },
  'pi.add.reviewPayments.threeDSecure.header': {
    id: 'pi.add.reviewPayments.threeDSecure.header',
    defaultMessage: 'Authorization {current} of {total}',
    description: '3D secure header',
  },
  'pi.add.reviewPayments.threeDSecureDPT.header': {
    id: 'pi.add.reviewPayments.threeDSecureDPT.header',
    defaultMessage: '{total} authorization(s) left',
    description: '3D secure header for DPT',
  },
  'pi.add.reviewPayments.submitProgress.successfulPayments.header': {
    id: 'pi.add.reviewPayments.submitProgress.successfulPayments.header',
    defaultMessage:
      '{numberOfSuccessfulPayments} {numberOfSuccessfulPayments, plural, one {payment has} other {payments have}} been successfully paid',
    description: 'Submit progress successful payments header',
  },

  'pi.add.reviewPayments.submitProgress.successfulDeferredPayments.header': {
    id: 'pi.add.reviewPayments.submitProgress.successfulDeferredPayments.header',
    defaultMessage:
      '{numberOfSuccessfulDeferredPayments} {numberOfSuccessfulDeferredPayments, plural, one {payment has} other {payments have}} been successfully registered and will be paid when authorised',
    description: 'Submit progress successful deferred payments header',
  },
  'pi.add.reviewPayments.submitProgress.scheduledPayments.header': {
    id: 'pi.add.reviewPayments.submitProgress.scheduledPayments.header',
    defaultMessage:
      '{numberOfScheduledPayments} {numberOfScheduledPayments, plural, one {payment has} other {payments have}} been scheduled',
    description: 'Submit progress scheduled payments header',
  },
  'pi.add.reviewPayments.submitProgress.numberOfPaymentsSentToApprovers.header': {
    id: 'pi.add.reviewPayments.submitProgress.numberOfPaymentsSentToApprovers.header',
    defaultMessage:
      '{numberOfPaymentsSentToApprovers} {numberOfPaymentsSentToApprovers, plural, one {payment has} other {payments have}} been submitted for approval',
    description: 'Submit progress sent to approvers header',
  },
  'pi.add.reviewPayments.submitProgress.numberOfApprovedPayments.header': {
    id: 'pi.add.reviewPayments.submitProgress.numberOfApprovedPayments.header',
    defaultMessage:
      '{numberOfApprovedPayments} {numberOfApprovedPayments, plural, one {payment is} other {payments are}} successfuly approved',
    description: 'Submit progress approved header',
  },
  'pi.add.reviewPayments.submitProgress.failedPayments.header': {
    id: 'pi.add.reviewPayments.submitProgress.failedPayments.header',
    defaultMessage: '{failedPayments} {failedPayments, plural, one {payment has} other {payments have}} failed',
    description: 'Submit progress failed payments header',
  },
  'pi.add.reviewPayments.submitProgress.successfulCharges.label': {
    id: 'pi.add.reviewPayments.submitProgress.successfulCharges.label',
    defaultMessage: 'Number of successful charges: {numberOfSuccessfulCharges} out of {totalCharges}',
    description: 'Submit progress successful charges header',
  },
  'pi.add.reviewPayments.submitProgress.failedCharges.label': {
    id: 'pi.add.reviewPayments.submitProgress.failedCharges.label',
    defaultMessage: 'Number of failed charges: {numberOfFailedCharges} out of {totalCharges}',
    description: 'Submit progress failed charges header',
  },
  'pi.add.reviewPayments.submitProgress.dividerText': {
    id: 'pi.add.reviewPayments.submitProgress.dividerText',
    defaultMessage: 'but unfortunately',
    description: 'Submit progress divider text between failed and success payments header',
  },
  'pi.add.reviewPayments.submitProgress.failedPayments.table.beneficiary': {
    id: 'pi.add.reviewPayments.submitProgress.failedPayments.table.beneficiary',
    defaultMessage: 'Beneficiary',
    description: 'Submit progress failed payments table column',
  },
  'pi.add.reviewPayments.submitProgress.failedPayments.table.dateDue': {
    id: 'pi.add.reviewPayments.submitProgress.failedPayments.table.dateDue',
    defaultMessage: 'Due date',
    description: 'Submit progress failed payments table column',
  },
  'pi.add.reviewPayments.submitProgress.failedPayments.table.amount': {
    id: 'pi.add.reviewPayments.submitProgress.failedPayments.table.amount',
    defaultMessage: 'Amount',
    description: 'Submit progress failed payments table column',
  },
  'pi.add.reviewPayments.submitProgress.button.failedPayments': {
    id: 'pi.add.reviewPayments.submitProgress.button.failedPayments',
    defaultMessage: 'Retry / Make another payment',
    description: 'Submit progress failed payments button label',
  },
  'pi.add.reviewPayments.submitProgress.button.successfulPayments': {
    id: 'pi.add.reviewPayments.submitProgress.button.successfulPayments',
    defaultMessage: 'Make another payment',
    description: 'Submit progress successful payments button label',
  },
  'pi.add.reviewPayments.submitProgress.inProgress.header': {
    id: 'pi.add.reviewPayments.submitProgress.inProgress.header',
    defaultMessage: 'Payments are in progress',
    description: 'Submit in progress label',
  },
  'pi.add.reviewPayments.submitProgress.error.header': {
    id: 'pi.add.reviewPayments.submitProgress.error.header',
    defaultMessage: 'An unexpected error has occurred. Please try again',
    description: 'Submit in progress label',
  },
  'pi.add.reviewPayments.submitError.GENERAL_ERROR': {
    id: 'pi.add.reviewPayments.submitError.GENERAL_ERROR',
    defaultMessage: 'GENERAL_ERROR label',
    description: 'Submit error - GENERAL_ERROR',
  },
  'pi.pay.reviewPayments.header': {
    id: 'pi.pay.reviewPayments.header',
    defaultMessage: 'Payments selected to be paid',
    description: 'Pay review payments page header',
  },
  'pi.pay.reviewPayments.noDataMessage': {
    id: 'pi.pay.reviewPayments.noDataMessage',
    defaultMessage: 'Please select payments',
    description: 'Pay review payments page no data message',
  },
  'pi.pay.selectPayments.header': {
    id: 'pi.pay.selectPayments.header',
    defaultMessage: 'Payments ready to be paid',
    description: 'Pay select payments page header',
  },
  'pi.pay.selectPayments.submitButton': {
    id: 'pi.pay.selectPayments.submitButton',
    defaultMessage: 'Select {numberOfPayments} {numberOfPayments, plural, one {payment} other {payments}} to be paid',
    description: 'Pay select payments page submit button label',
  },
  'pi.pay.selectPayments.submitButton.disabled': {
    id: 'pi.pay.selectPayments.submitButton.disabled',
    defaultMessage: 'Select payments',
    description: 'Pay select payments page submit button disabled label',
  },
  'pi.pay.selectPayments.noDataMessage': {
    id: 'pi.pay.selectPayments.noDataMessage',
    defaultMessage: 'There are no payments ready to be paid',
    description: 'Pay select payments page no data message',
  },
  'pi.review.reviewPayments.header': {
    id: 'pi.review.reviewPayments.header',
    defaultMessage: 'Payments selected for approval',
    description: 'Review payments page header',
  },
  'pi.review.reviewPayments.approveAndPayOptions.approveAndPay': {
    id: 'pi.review.reviewPayments.approveAndPayOptions.approveAndPay',
    defaultMessage: 'Approve & pay',
    description: 'Approve and pay option',
  },
  'pi.review.reviewPayments.approveAndPayOptions.approveOnly': {
    id: 'pi.review.reviewPayments.approveAndPayOptions.approveOnly',
    defaultMessage: 'Approve only',
    description: 'Approve and pay option',
  },
  'pi.review.reviewPayments.additionalApproval.header': {
    id: 'pi.review.reviewPayments.additionalApproval.header',
    defaultMessage:
      '{numberOfPayments} {numberOfPayments, plural, one {payment needs} other {payments need}} additional approval',
    description: 'Additional approval header',
  },
  'pi.review.reviewPayments.additionalApproval.text': {
    id: 'pi.review.reviewPayments.additionalApproval.text',
    defaultMessage:
      'You account setting require dual approval for payments by <bold>{numberOfSignaturesRequired} users</bold>  and the following payments have not reached that number yet',
    description: 'Additional approval text',
  },
  'pi.review.reviewPayments.noDataMessage': {
    id: 'pi.review.reviewPayments.noDataMessage',
    defaultMessage: 'Please select payments',
    description: 'Review payments page no data message',
  },
  'pi.review.selectPayments.header': {
    id: 'pi.review.selectPayments.header',
    defaultMessage: 'Payments pending approval',
    description: 'Select payments page header',
  },
  'pi.review.selectPayments.submitButton': {
    id: 'pi.review.selectPayments.submitButton',
    defaultMessage: 'Select {numberOfPayments} {numberOfPayments, plural, one {payment} other {payments}} for approval',
    description: 'Select payments page submit button label',
  },
  'pi.review.selectPayments.submitButton.disabled': {
    id: 'pi.review.selectPayments.submitButton.disabled',
    defaultMessage: 'Select payments',
    description: 'Select payments page submit button disabled label',
  },
  'pi.review.selectPayments.submitButton.disabled.noSource': {
    id: 'pi.review.selectPayments.submitButton.disabled.noSource',
    defaultMessage: 'Payment without source is selected',
    description: 'Select payments page submit button disabled because no source label',
  },
  'pi.review.selectPayments.noDataMessage': {
    id: 'pi.review.selectPayments.noDataMessage',
    defaultMessage: 'There are no pending payments for approval',
    description: 'Select payments page no data message',
  },
  'pi.selectCard.tabs.savedCard': {
    id: 'pi.selectCard.tabs.savedCard',
    defaultMessage: 'Saved Card',
    description: 'Select card tab option',
  },
  'pi.selectCard.tabs.new': {
    id: 'pi.selectCard.tabs.new',
    defaultMessage: 'New',
    description: 'Select card tab option',
  },
  'pi.selectCard.assignToAll': {
    id: 'pi.selectCard.assignToAll',
    defaultMessage: 'Assign to all selected ({numberOfSelectedPayments})',
    description: 'Assign to all checkbox label',
  },
  'pi.selectCard.submitButton': {
    id: 'pi.selectCard.submitButton',
    defaultMessage: 'Assign',
    description: 'Assign selected card to PI button label',
  },
  'pi.attachment.uploader.text': {
    id: 'pi.attachment.uploader.text',
    defaultMessage: 'Click or drag file to this area to upload',
    description: 'Attachment uploader text',
  },
  'pi.attachment.uploader.hintText': {
    id: 'pi.attachment.uploader.hintText',
    defaultMessage: 'Upload .pdf, .jpeg, .jpg or .png file (10.00 MB Max)',
    description: 'Attachment uploader text hint',
  },
  'pi.attachment.error.alert.message': {
    id: 'pi.attachment.error.alert.message',
    defaultMessage: 'One or more uploads failed',
    description: 'Attachment uploader error alert message',
  },
  'pi.attachment.commonError.alert.description': {
    id: 'pi.attachment.commonError.alert.description',
    defaultMessage: 'Please ensure the file you upload is in a supported format and size, then try again.',
    description: 'Attachment uploader error alert description',
  },
  'pi.attachment.fileError': {
    id: 'pi.attachment.fileError',
    defaultMessage:
      'File invalid. Please delete and re-upload a file that meets the supported format and size requirements.',
    description: 'Attachment uploader error when file size exceeds maximum limit',
  },
  'pi.attachment.updateButton': {
    id: 'pi.attachment.updateButton',
    defaultMessage: 'Update',
    description: 'Update attachment button label',
  },
  'pi.attachment.comment.placeholder': {
    id: 'pi.attachment.comment.placeholder',
    defaultMessage: 'Comment',
    description: 'Comment textarea placeholder',
  },
  'pi.import.importer.label': {
    id: 'pi.import.importer.label',
    defaultMessage: 'Select file to import',
    description: 'Importer label',
  },
  'pi.import.importer.instruction': {
    id: 'pi.import.importer.instruction',
    defaultMessage: 'Click or drag file to this area to upload',
    description: 'Importer instruction',
  },
  'pi.import.importer.instruction.2': {
    id: 'pi.import.importer.instruction.2',
    defaultMessage: 'Select one or multiple files. Supported formats are listed below',
    description: 'Importer instruction',
  },
  'pi.import.supportedFormatExample.label': {
    id: 'pi.import.supportedFormatExample.label',
    defaultMessage: 'More about file formats and example templates',
    description: 'Show supported file example label',
  },
  'pi.import.supportedFormatExample.helpText': {
    id: 'pi.import.supportedFormatExample.helpText',
    defaultMessage:
      'The recomended import file format is our Excel-based format. In addition we also support the XML-based format pain.002.001.03',
    description: 'Supported file help text',
  },
  'pi.import.supportedFormatList.downlnoad.excel': {
    id: 'pi.import.supportedFormatList.downlnoad.excel',
    defaultMessage: 'Download our Excel file format template',
    description: 'File template download label',
  },
  'pi.import.numberOfSuccessfullImports': {
    id: 'pi.import.numberOfSuccessfullImports',
    defaultMessage:
      '{numberOfSuccessfullImports} successfull {numberOfSuccessfullImports, plural, one {import} other {imports}}',
    description: 'Number of successfull imports',
  },
  'pi.import.numberOfFailedImports': {
    id: 'pi.import.numberOfFailedImports',
    defaultMessage: '{numberOfFailedImports} failed {numberOfFailedImports, plural, one {import} other {imports}}',
    description: 'Number of failed imports',
  },
  'pi.import.errorTable.message': {
    id: 'pi.import.errorTable.message',
    defaultMessage: 'Error',
    description: 'Import error table column header',
  },
  'pi.import.errorTable.beneficiary': {
    id: 'pi.import.errorTable.beneficiary',
    defaultMessage: 'Beneficiary',
    description: 'Import error table column header',
  },
  'pi.import.errorTable.dueDate': {
    id: 'pi.import.errorTable.dueDate',
    defaultMessage: 'Due date',
    description: 'Import error table column header',
  },
  'pi.import.errorTable.amount': {
    id: 'pi.import.errorTable.amount',
    defaultMessage: 'Amount',
    description: 'Import error table column header',
  },
  'pi.import.tab.inProgress': {
    id: 'pi.import.tab.inProgress',
    defaultMessage: 'In-progress',
    description: 'Imports tab label',
  },
  'pi.import.tab.history': {
    id: 'pi.import.tab.history',
    defaultMessage: 'History',
    description: 'Imports tab label',
  },
  'pi.import.noImportsInProgress': {
    id: 'pi.import.noImportsInProgress',
    defaultMessage: 'No imports in progress',
    description: 'No imports to show label',
  },
  'pi.import.noCompletedImports': {
    id: 'pi.import.noCompletedImports',
    defaultMessage: 'No completed imports',
    description: 'No imports to show label',
  },
  'pi.modal.delete.header': {
    id: 'pi.modal.delete.header',
    defaultMessage: 'Please confirm',
    description: 'Delete payment modal header',
  },
  'pi.modal.delete.text': {
    id: 'pi.modal.delete.text',
    defaultMessage: 'Do you want to delete this {numberOfPayments, plural, one {payment} other {payments}}?',
    description: 'Delete payment modal text',
  },
  'pi.modal.refund.text': {
    id: 'pi.modal.refund.text',
    defaultMessage: 'Do you want to refund this {numberOfPayments, plural, one {payment} other {payments}}?',
    description: 'Refund payment modal text',
  },
  'pi.modal.button.cancel': {
    id: 'pi.modal.button.cancel',
    defaultMessage: 'Cancel',
    description: 'Delete payment modal cancel button label',
  },
  'pi.modal.button.confirmDelete': {
    id: 'pi.modal.button.confirmDelete',
    defaultMessage: 'Delete',
    description: 'Delete payment modal confirm button label',
  },
  'pi.modal.button.confirmRefund': {
    id: 'pi.modal.button.confirmRefund',
    defaultMessage: 'Refund',
    description: 'Refund payment modal confirm button label',
  },
  'pi.details.creator': {
    id: 'pi.details.creator',
    defaultMessage: 'Creator',
    description: 'Label for creator of the payment',
  },
  'pi.details.signer': {
    id: 'pi.details.signed',
    defaultMessage: 'Signer',
    description: 'Label for signer of the payment',
  },
  'pi.details.payer': {
    id: 'pi.details.payer',
    defaultMessage: 'Payer',
    description: 'Label for payer of the payment',
  },
  'pi.details.payment': {
    id: 'pi.details.payment',
    defaultMessage: 'Payment',
    description: 'Payment details label',
  },
  'pi.details.paymentCards': {
    id: 'pi.details.paymentCards',
    defaultMessage: 'Payment cards',
    description: 'Payment details label',
  },
  'pi.details.createdBy': {
    id: 'pi.details.createdBy',
    defaultMessage: 'Created by',
    description: 'Payment details label',
  },
  'pi.details.signatures': {
    id: 'pi.details.signatures',
    defaultMessage: 'Signatures',
    description: 'Payment details label',
  },
  'pi.details.requireSignatureBy': {
    id: 'pi.details.requireSignatureBy',
    defaultMessage: 'Require signature by',
    description: 'Payment details label',
  },
  'pi.details.round': {
    id: 'pi.details.round',
    defaultMessage: 'Round',
    description: 'Payment details label',
  },
  'pi.details.signedBy': {
    id: 'pi.details.signedBy',
    defaultMessage: 'Signed by',
    description: 'Payment details label',
  },
  'pi.details.cardTitle': {
    id: 'pi.details.cardTitle',
    defaultMessage: 'Title',
    description: 'Payment details card name label',
  },
  'pi.details.cardNubmer': {
    id: 'pi.details.cardNubmer',
    defaultMessage: 'Card number',
    description: 'Payment details card number label',
  },
  'pi.details.attachment': {
    id: 'pi.details.attachment',
    defaultMessage: 'Attachment',
    description: 'Payment details label',
  },
  'pi.details.recipient': {
    id: 'pi.details.recipient',
    defaultMessage: 'Recipient',
    description: 'Payment details recipient header label',
  },
  'pi.details.receipt': {
    id: 'pi.details.receipt',
    defaultMessage: 'Receipt',
    description: 'Payment details receipt button label',
  },
  'pi.details.recurring': {
    id: 'pi.details.recurring',
    defaultMessage: 'Recurring',
    description: 'Payment details template recurring header label',
  },
  'pi.details.receiptOption.withFees': {
    id: 'pi.details.receiptOption.withFees',
    defaultMessage: 'Receipt incl. service fees',
    description: 'Payment details receipt option button label',
  },
  'pi.details.receiptOption.withoutFees': {
    id: 'pi.details.receiptOption.withoutFees',
    defaultMessage: 'Receipt ecxl. service fees',
    description: 'Payment details receipt option button label',
  },
  'pi.details.receiptOption.remittanceAdvice': {
    id: 'pi.details.receiptOption.remittanceAdvice',
    defaultMessage: 'Remittance advice',
    description: 'Payment details receipt option button label',
  },
  'pi.foreignCurrencyWarning.message': {
    id: 'pi.foreignCurrencyWarning.message',
    defaultMessage: 'Foreign currency',
    description: 'Foreign currency warning label',
  },
  'pi.foreignCurrencyWarning.text': {
    id: 'pi.foreignCurrencyWarning.text',
    defaultMessage:
      'Your making payments in a foreign currency. This might include extra charges on your card from your bank.',
    description: 'Foreign currency warning text',
  },
  'pi.deletePayments.selected': {
    id: 'pi.deletePayments.selected',
    defaultMessage: 'Delete selected {numberOfPayments, plural, =0 {} other {({numberOfPayments})}}',
    description: 'Delete selected payments label',
  },
  'pi.deletePayments.all': {
    id: 'pi.deletePayments.all',
    defaultMessage: 'Delete all {numberOfPayments, plural, =0 {} other {({numberOfPayments})}}',
    description: 'Delete all payments label',
  },
  'pi.duplicatePayments.heading': {
    id: 'pi.duplicatePayments.heading',
    defaultMessage: 'Duplicate Payments',
    description: 'Duplicate payments heading',
  },
  'pi.duplicatePayments.resolve.btn.label': {
    id: 'pi.duplicatePayments.resolve.btn.label',
    defaultMessage: 'Resolve',
    description: 'Duplicate payments resove button label',
  },
  'pi.duplicatePayments.resolve.all.btn.label': {
    id: 'pi.duplicatePayments.resolve.all.btn.label',
    defaultMessage: 'Resolve all',
    description: 'Duplicate payments resolve all button label',
  },
  'pi.duplicatePayments.content': {
    id: 'pi.duplicatePayments.content',
    defaultMessage: '{currency} {value} to {beneficiary}',
    description: 'Duplicate payments item content',
  },
  'pi.supplierFunded.label': {
    id: 'pi.supplierFunded.label',
    defaultMessage: 'Supplier funded',
    description: 'This is used for Supplier funded tag on payment',
  },
  'pi.payments.filter.btn': {
    id: 'pi.payments.filter.btn',
    defaultMessage: 'Filter',
    description: 'This is the filter button label',
  },
  'pi.add.payments.filter.dropdown.state': {
    id: 'pi.add.payments.filter.dropdown.state',
    defaultMessage: 'State',
    description: 'This is the dropdown state filter button label',
  },
  'pi.add.payments.filter.dropdown.currency': {
    id: 'pi.add.payments.filter.dropdown.currency',
    defaultMessage: 'Currency',
    description: 'This is the dropdown currency filter button label',
  },
  'pi.add.payments.filter.dropdown.card': {
    id: 'pi.add.payments.filter.dropdown.card',
    defaultMessage: 'Card',
    description: 'This is the dropdown card filter button label',
  },
  'pi.add.payments.filter.beneficiary': {
    id: 'pi.add.payments.filter.beneficiary',
    defaultMessage: 'Recipient name',
    description: 'This is the beneficiary filter label',
  },
  'pi.not.added': {
    id: 'pi.not.added',
    defaultMessage: 'No payments added',
    description: 'No payments added message',
  },
  'pi.not.found': {
    id: 'pi.not.found',
    defaultMessage: 'No payments found',
    description: 'No payments found message',
  },
  'pi.add.addPayments.form.card.payment.success': {
    id: 'pi.add.addPayments.form.card.payment.success',
    defaultMessage: 'Your payment is added',
    description: 'Payment was successful',
  },
  'pi.addPayments.form.card.bin.error': {
    id: 'pi.addPayments.form.card.bin.error',
    defaultMessage: 'Selected card is blocked',
    description: 'Blocked bin for the card',
  },
  'pi.addPayments.form.card.foreign.currency.error': {
    id: 'pi.addPayments.form.card.foreign.currency.error',
    defaultMessage: 'Cannot add payment since payment currency is not matching card currency.',
    description: 'Blocked payment by foreign currency rule label',
  },
  'pi.updatePayments.form.card.bin.error': {
    id: 'pi.updatePayments.form.card.bin.error',
    defaultMessage: 'Selected card is blocked',
    description: 'Blocked bin for the card',
  },
  'pi.add.addPayments.form.payment.update.success': {
    id: 'pi.add.addPayments.form.payment.update.success',
    defaultMessage: 'Your payment is updated',
    description: 'Payment Update was successful',
  },
  'pi.add.addPayments.form.payment.update.fail': {
    id: 'pi.add.addPayments.form.payment.update.fail',
    defaultMessage: 'Your payment update failed',
    description: 'Payment Update failed',
  },
  'pi.add.addPayments.action.viewPayments': {
    id: 'pi.add.addPayments.action.viewPayments',
    defaultMessage: 'View and pay added payments',
    description: 'View payments after adding them',
  },
  'pi.status.added': {
    id: 'pi.status.added',
    defaultMessage: 'ADDED',
    description: 'Added status of the PI',
  },
  'pi.status.ready': {
    id: 'pi.status.ready',
    defaultMessage: 'READY',
    description: 'Ready status of the PI',
  },
  'pi.status.requireSignature': {
    id: 'pi.status.requireSignature',
    defaultMessage: 'REQUIRE_SIGNATURE',
    description: 'Require signature status of the PI',
  },
  'pi.status.processing': {
    id: 'pi.status.processing',
    defaultMessage: 'PROCESSING',
    description: 'Processing status of the PI',
  },
  'pi.status.pending': {
    id: 'pi.status.pending',
    defaultMessage: 'PENDING',
    description: 'Pending status of the PI',
  },
  'pi.status.pending.authorization': {
    id: 'pi.status.pending.authorization',
    defaultMessage: 'PENDING AUTHORIZATION',
    description: 'Pending authorization status of the PI',
  },
  'pi.status.bank': {
    id: 'pi.status.bank',
    defaultMessage: 'BANK',
    description: 'Bank Status of the PI',
  },
  'pi.status.paid': {
    id: 'pi.status.paid',
    defaultMessage: 'PAID',
    description: 'Paid Status of the PI',
  },
  'pi.status.inReview': {
    id: 'pi.status.inReview',
    defaultMessage: 'IN REVIEW',
    description: 'Error Status of the PI',
  },
  'pi.status.deleted': {
    id: 'pi.status.deleted',
    defaultMessage: 'DELETED',
    description: 'Deleted Status of the PI',
  },
  'pi.status.canceled': {
    id: 'pi.status.canceled',
    defaultMessage: 'CANCELED',
    description: 'Canceled Status of the PI',
  },
  'pi.status.active': {
    id: 'pi.status.active',
    defaultMessage: 'Active',
    description: 'Active Status of the PI',
  },
  'pi.status.completed': {
    id: 'pi.status.completed',
    defaultMessage: 'COMPLETED',
    description: 'Completed Status of the PI',
  },
  'pi.beneficiary.blocked.creditcard': {
    id: 'pi.beneficiary.blocked.creditcard',
    defaultMessage:
      "{beneficiaryInfo} is registered as a credit card issuer and Billhop can't accept payments to this kind of recipient.",
    description: 'Blocked beneficiary with type credit card',
  },
  'pi.beneficiary.blocked.collector': {
    id: 'pi.beneficiary.blocked.collector',
    defaultMessage:
      "{beneficiaryInfo} is registered as a debt collector and Billhop can't accept payments to this kind of recipient.",
    description: 'Blocked beneficiary with type collector',
  },
  'pi.beneficiary.blocked.fodge': {
    id: 'pi.beneficiary.blocked.fodge',
    defaultMessage: "Billhop can't process payments to Enforcement Authorities (Collection Agencies).",
    description: 'Blocked beneficiary with type fodge',
  },
  'pi.beneficiary.blocked.general': {
    id: 'pi.beneficiary.blocked.general',
    defaultMessage:
      '{beneficiaryInfo} is blocked. Payment cannot be made to this recipient. At Billhop reciepints such as credit card issuers, collection agencies or other organizations that violates the terms of the card networks are blocked. The recipient you have enterd is among those categories.',
    description: 'General message for blocked beneficiary',
  },
  'pi.card.not.selected.title': {
    id: 'pi.card.not.selected.title',
    defaultMessage: 'No card selected',
    description: 'Card not selected title',
  },
  'pi.card.not.selected.description': {
    id: 'pi.card.not.selected.description',
    defaultMessage: 'Card not selected. Payer can add card in final approval step.',
    description: 'Card not selected description',
  },
  'pi.newBeneficiaryWarning.message': {
    id: 'pi.newBeneficiaryWarning.message',
    defaultMessage: 'This payment has a new recipient.',
    description: 'New beneficiary warning mesage',
  },
  'pi.multipleNewBeneficiaryWarning.message': {
    id: 'pi.multipleNewBeneficiaryWarning.message',
    defaultMessage: 'These payments has new recipients.',
    description: 'New Mutiple beneficiary warning mesage',
  },
  'pi.newBeneficiaryConfirmation.message': {
    id: 'pi.newBeneficiaryConfirmation.message',
    defaultMessage: 'I confirm that I am approving payments to a new recipient.',
    description: 'New beneficiary confirmation mesage',
  },
  'pi.multipleNewBeneficiaryConfirmation.message': {
    id: 'pi.multipleNewBeneficiaryConfirmation.message',
    defaultMessage: 'I confirm that I am approving payments to new recipients.',
    description: 'Multiple New beneficiary confirmation mesage',
  },
  'pi.add.addPayments.form.reference.errorMessage.invalidCharacters': {
    id: 'pi.add.addPayments.form.reference.errorMessage.invalidCharacters',
    defaultMessage:
      "You are allowed to use lower and uppercase letters and numbers between 0-9, space and the following characters: / - ? : ( ) . , ' +.",
    description: 'Error message when the reference input contains invalid characters',
  },
  'pi.add.addPayments.form.reference.errorMessage.maxLength': {
    id: 'pi.add.addPayments.form.reference.errorMessage.maxLength',
    defaultMessage: "Reference can't be more than {maxLength} characters",
    description: 'Error message when the reference input is too long',
  },
  'pi.submitProgress.no.await.case.message': {
    id: 'pi.submitProgress.no.await.case.message',
    defaultMessage:
      'Your {totalNumberOfPayments} payments are being processed, we will send an email confirmation when they are charged.',
    description: 'Number of payments being processed',
  },
  'pi.add.addPayments.form.reference.tooltip': {
    id: 'pi.add.addPayments.form.reference.tooltip',
    defaultMessage: 'Your reference could be truncated depending on the limitations of the destination bank.',
    description: 'Tooltip message for reference field explaining potential truncation by the bank.',
  },
})
