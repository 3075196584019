export default function mapVcnProviderPropertiesToFrom(
  vcnProviderProperties: Array<Record<string, string>>
): Record<string, string> {
  const normalizedProperties = vcnProviderProperties.map((obj) => {
    const key = Object.keys(obj)[0]
    const value = obj[key]
    return { key, value }
  })

  const result = normalizedProperties.reduce((acc: Record<string, string>, { key, value }) => {
    acc[key] = value
    return acc
  }, {})

  return result
}
