import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  dropdownFilterWrapper: css`
    display: flex;
    flex-direction: column;

    .ant-descriptions-view {
      border: none !important;
    }

    .ant-collapse {
      margin-bottom: ${token.marginMD}px;

      .ant-collapse-item {
        border-radius: 10px !important;
        margin-bottom: 0px !important;

        &:last-child {
          border-radius: 10px !important;
        }
      }
    }
  `,
  noBorder: css`
    tr,
    th,
    td {
      border: 0 !important;
      background-color: white !important;
    }
    > div {
      border: 0;
      .ant-descriptions-item-label {
        background: transparent;
        width: 10rem;
        font-weight: 400;
        padding-left: 0 !important;
        padding-right: 4px !important;
      }
    }
  `,
  approverRounds: css`
    display: flex;
    align-items: center;
  `,
  roundLabel: css`
    color: #8c8c8c;
  `,
  attachments: css`
    button {
      padding-left: 0;
      white-space: pre-wrap;
      text-align: left;
    }
  `,
  actionPanel: css`
    button {
      padding-left: 0;
    }
  `,
  attachmentComment: css`
    font-size: ${token.fontSize}px;
  `,
}))

export default useStyles
