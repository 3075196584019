import { Descriptions } from 'antd'
import { useEffect, useState } from 'react'
import { getDbis } from 'api/cardio'
import usePaymentDetailsStyle from 'pages/PaymentInstructions/PaymentInstructionDetails.style'
import { VcnProvider } from 'types/cardio'
import { TypeProperties } from 'types/source'
import { useTranslation } from 'utils/helpers'
import * as Sentry from '@sentry/react'

interface DbiDescriptionsProps {
  vcnProviderProperties: TypeProperties['vcnProviderProperties']
}

interface VcnField {
  name: string
  value?: string
  translationKey?: string
}

const DbiDescriptions = ({ vcnProviderProperties }: DbiDescriptionsProps) => {
  const { styles } = usePaymentDetailsStyle()
  const [vcnFields, setVcnFields] = useState<VcnField[]>([])
  const t = useTranslation()

  const fetchAndMapVcnFields = async () => {
    if (!vcnProviderProperties) return
    try {
      const dbis = await getDbis(VcnProvider.AIRPLUS)

      const mappedFields: VcnField[] = vcnProviderProperties.map((item) => {
        const key = Object.keys(item).find((key) => dbis.some((dbi) => dbi.name === key))
        const dbi = dbis.find((dbi) => dbi.name === key)
        return {
          name: key || '',
          value: key ? item[key] : '',
          translationKey: dbi?.translationKey,
        }
      })

      setVcnFields(mappedFields)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    void fetchAndMapVcnFields()
  }, [vcnProviderProperties])

  return (
    <Descriptions size="small" className={styles.noBorder} bordered>
      {vcnFields.map(({ name, value, translationKey }) => (
        <Descriptions.Item key={name} label={`(${name}) ${translationKey ? t(translationKey) : ''}`} span={3}>
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}

export default DbiDescriptions
