/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { flatten, unionBy, uniq, sortBy, isEqual } from 'lodash'
import PaymentForm from './PaymentForm'
import * as beneficiariesApi from 'api/beneficiary'
import * as gregApi from 'api/greg'
import * as piApi from 'api/paymentInstruction'
import * as blacklistApi from 'api/beneficiary'
import {
  getCardNetworksPaymentDays,
  getCurrencies,
  getCardNetworkDateDue,
  getPaymentLimit,
  getRecurrenceDataFromTemplate,
  formatCardNetworksBankDays,
  mapPaymentInstructionToForm,
  mapPaymentInstructionTemplateToForm,
  mapFormToPaymentInstruction,
  mapFormToPaymentInstructionTemplate,
  DEFAULT_RECURRENCE_DATA,
} from '../../utils'
import { useTranslation } from 'utils/helpers'
import { Source } from 'types/source'
import { BillhopError } from 'types/general'
import {
  CardNetworkBankDay,
  CardNetworkPaymentDays,
  PaymentInstruction,
  PaymentInstructionTemplate,
  PaymentFormData,
  Recurrence,
  PAYMENT_KIND,
  Attachment,
} from 'types/paymentInstruction'
import { PaymentGateway } from 'types/rules'
import { Beneficiary, BlacklistEntry } from 'types/beneficiary'
import { Holiday, BankDate } from 'types/greg'
import { FilterBody } from 'components/Filter/Filter'
import { pi } from 'lang/definitions/paymentInstruction'
import { message, notification } from 'antd'
import { useCurrentPayment } from 'stores/Payment'
import { notifyError } from 'utils/error'
import { useActiveUser, usePaymentInstructionReferencePrefixAndSuffix } from 'hooks'
import { useCurrentPaymentUtils } from 'stores/Payment/hooks'
import { useSession } from 'stores/session'

interface BankDateWithDays {
  bankDate: BankDate
  days: number
  cached?: boolean
}

interface CreatePaymentsProps {
  createPaymentInstruction: (pi: PaymentInstruction) => Promise<PaymentInstruction>
  deletePaymentInstruction: (paymentInstructionId: string) => void
  deleteTemplate: (templateId: string) => void
  cardNetworks: string[]
  gateways: PaymentGateway[]
  createPaymentInstructionTemplate: (template: PaymentInstructionTemplate) => Promise<PaymentInstructionTemplate>
  updatePaymentInstruction: (pi: PaymentInstruction) => Promise<PaymentInstruction>
  updatePaymentInstructionTemplate: (template: PaymentInstructionTemplate) => Promise<PaymentInstructionTemplate>
  replacePaymentInstructionWithTemplate: (template: PaymentInstructionTemplate) => Promise<PaymentInstructionTemplate>
  replaceTemplateWithPaymentInstruction: (paymentInstruction: PaymentInstruction) => Promise<PaymentInstruction>
  cancelEdit: () => void
  createAttachment: (paymentId: string, attachment: Attachment, kind: PAYMENT_KIND) => Promise<void>
  deleteAttachment: (paymentId: string, attachmentId: string, kind: PAYMENT_KIND) => Promise<void>
}
let bankDaysGlobal: BankDateWithDays[] = []

const CreatePayments = (props: CreatePaymentsProps): React.JSX.Element => {
  const {
    cardNetworks,
    gateways,
    createPaymentInstruction,
    deletePaymentInstruction,
    deleteTemplate,
    createPaymentInstructionTemplate,
    updatePaymentInstruction,
    updatePaymentInstructionTemplate,
    replacePaymentInstructionWithTemplate,
    replaceTemplateWithPaymentInstruction,
    cancelEdit,
    createAttachment,
    deleteAttachment,
  } = props

  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const rules = sessionState.rules!

  const t = useTranslation()

  const { setBeneficiariesState, getBeneficiaries } = useCurrentPaymentUtils()

  const {
    state: {
      selectedCard,
      selectedBeneficiary,
      selectedAmount,
      selectedCurrency,
      cards,
      paymentInstruction,
      paymentInstructionTemplate,
      readySet,
    },
    actions: {
      dispatchCurrentAmountToStore,
      dispatchCurrentCardToStore,
      dispatchCurrentBeneficiaryToStore,
      dispatchCurrentCurrency,
    },
  } = useCurrentPayment()

  const intl = useIntl()
  const { profileId } = useActiveUser()

  const [initialFormData, setInitialFormData] = useState<PaymentFormData>({
    recurring: false,
  })
  const [initialFormDataKind, setInitialFormDataKind] = useState<PAYMENT_KIND>()
  const [paymentLimit, setPaymentLimit] = useState(0)
  const [bankHolidays, setBankHolidays] = useState<Holiday[]>([])
  const [currencies, setCurrencies] = useState<string[]>([])
  const [cardNetworksBankDays, setCardNetworksBankDays] = useState<CardNetworkBankDay[]>([])

  const [formattedCardNetworksBankDays, setFormattedCardNetworksBankDays] = useState<CardNetworkBankDay[]>([])
  const [dateDue, setDateDue] = useState<dayjs.Dayjs>()
  const [recurrenceData, setRecurrenceData] = useState<Recurrence>()
  const [isDateDueUpdated, setIsDateDueUpdated] = useState(false)
  const [isRecurrenceDataUpdated, setIsRecurrenceDataUpdated] = useState(false)
  const [isAttachmentUpdated, setIsAttachmentUpdated] = useState(false)
  const [attachment, setAttachment] = useState<Attachment>()
  const [blacklists, setBlacklists] = useState<Array<BlacklistEntry>>()
  const [blockType, setBlockType] = useState<string>()
  const [currentCardNetwork, setCurrentCardNetwork] = useState<string>()
  const [isBlacklisted, setIsBlacklisted] = useState<boolean>(false)
  const [isInWhitelist, setIsInWhitelist] = useState<boolean>(true)

  const { prefixLength, suffixLength } = usePaymentInstructionReferencePrefixAndSuffix()

  useEffect(() => {
    if (paymentInstruction) {
      const { sourceId, beneficiaryId, amount, currency, dateDue, attachments, source } = paymentInstruction

      // because mapped vcn pool have set id as accountNumber
      if (source && source.type === 'vcn' && source.typeProperties && source.typeProperties.accountNumber) {
        selectCard(source.typeProperties.accountNumber)
      } else {
        selectCard(sourceId)
      }

      void selectBeneficiary(beneficiaryId)

      dispatchCurrentAmountToStore(amount)
      dispatchCurrentCurrency(currency)
      setDateDue(dayjs(dateDue))

      const initialData = mapPaymentInstructionToForm(paymentInstruction, {
        prefixLength,
        suffixLength,
      })

      setInitialFormData(initialData)
      setInitialFormDataKind(PAYMENT_KIND.PAYMENT_INSTRUCTION)
      if (attachments?.length) {
        setAttachment(attachments[0])
      } else {
        setAttachment(undefined)
      }

      setRecurrenceData(undefined)
      setIsDateDueUpdated(false)
      setIsRecurrenceDataUpdated(false)
      setIsAttachmentUpdated(false)
    }
    if (!paymentInstruction && !paymentInstructionTemplate) {
      resetState()
    }
  }, [paymentInstruction])

  useEffect(() => {
    if (readySet === undefined) {
      resetState()
    }
  }, [readySet])

  useEffect(() => {
    dispatchCurrentAmountToStore(selectedAmount)
  }, [selectedAmount])

  useEffect(() => {
    if (paymentInstructionTemplate) {
      const { paymentInstructionProperties, dateDueNext } = paymentInstructionTemplate

      const { sourceId, beneficiaryId, amount, currency, attachments, source } = paymentInstructionProperties

      // because mapped vcn pool have set id as accountNumber
      if (source && source.type === 'vcn' && source.typeProperties && source.typeProperties.accountNumber) {
        selectCard(source.typeProperties.accountNumber)
      } else {
        selectCard(sourceId)
      }

      void selectBeneficiary(beneficiaryId)
      dispatchCurrentAmountToStore(amount)
      dispatchCurrentCurrency(currency)
      setDateDue(dayjs(dateDueNext))

      const initialData = mapPaymentInstructionTemplateToForm(paymentInstructionTemplate, {
        prefixLength,
        suffixLength,
      })
      setInitialFormData(initialData)
      setInitialFormDataKind(PAYMENT_KIND.TEMPLATE)
      if (attachments?.length) {
        setAttachment(attachments[0])
      } else {
        setAttachment(undefined)
      }

      const recurrenceData = getRecurrenceDataFromTemplate(paymentInstructionTemplate)
      setRecurrenceData(recurrenceData)
      setIsDateDueUpdated(false)
      setIsRecurrenceDataUpdated(false)
      setIsAttachmentUpdated(false)
    }

    if (!paymentInstructionTemplate && !paymentInstruction) {
      resetState()
    }
  }, [paymentInstructionTemplate])

  useEffect(() => {
    const countryCodes = getCountryCodesForUserAndBeneficiary()

    void getBankHolidays(countryCodes)
  }, [user, selectedBeneficiary])

  useEffect(() => {
    if (!selectedBeneficiary) {
      dispatchCurrentCurrency(undefined)
      return
    }

    calculateCurrencies(selectedBeneficiary)
  }, [selectedBeneficiary, rules])

  useEffect(() => {
    if (selectedCurrency) {
      const limit = getPaymentLimit(rules, selectedCurrency)
      setPaymentLimit(limit)
    }
  }, [selectedCurrency, rules])

  useEffect(() => {
    formatAndSetCardNetworksBankDays(cardNetworksBankDays)
  }, [selectedCard, dateDue])

  useEffect(() => {
    let cardNetworks: string[] = []

    if (cards.length) {
      cardNetworks = uniq(cards.map((card: Source): string => card.typeProperties.cardNetwork))
    } else {
      cardNetworks = Object.keys(rules.logic.cardNetworks.rule).filter(
        (cardNetwork: string) => rules.logic.cardNetworks.rule[cardNetwork]
      )
    }

    const clearingNetwork = selectedBeneficiary?.clearingNetwork
    const paymentDays = cardNetworks.map((cardNetwork: string) =>
      getCardNetworksPaymentDays(rules, cardNetwork, clearingNetwork, selectedCurrency, selectedAmount)
    )
    void getBankDays(paymentDays)
  }, [cards, selectedAmount, selectedCurrency])

  useEffect(() => {
    if (cards.length === 1 && !paymentInstruction && !paymentInstructionTemplate) {
      dispatchCurrentCardToStore(cards[0])
      setCurrentCardNetwork(cards[0].typeProperties.cardNetwork)
      setInitialFormData({ ...initialFormData, sourceId: cards[0].id })
    }
  }, [cards, paymentInstruction, paymentInstructionTemplate])

  useEffect(() => {
    if (selectedBeneficiary) {
      const { accountNumber, routingNumber } = selectedBeneficiary
      void searchBlacklists(accountNumber, routingNumber)
    } else {
      setIsBlacklisted(false)
    }
  }, [currentCardNetwork, selectedBeneficiary])

  const calculateCurrencies = (beneficiary: Beneficiary): void => {
    if (beneficiary.currency) {
      setCurrencies([beneficiary.currency])
      dispatchCurrentCurrency(beneficiary.currency)
      return
    }

    const findCurrencies = getCurrencies(rules, beneficiary.clearingNetwork)

    if (findCurrencies) {
      setCurrencies(findCurrencies)
      dispatchCurrentCurrency(findCurrencies[0])
    }
  }

  const searchBlacklists = async (
    accountNumber: string,
    routingNumber: string | undefined = undefined
  ): Promise<void> => {
    try {
      const filter: FilterBody = {
        accountNumber: accountNumber.toUpperCase(),
      }
      if (routingNumber) {
        filter.routingNumber = routingNumber
      }

      if (currentCardNetwork) {
        filter.cardNetworks = [currentCardNetwork]
      }

      const { blacklist } = await blacklistApi.searchBlacklist(filter)
      if (blacklist) {
        setBlacklists(blacklist)
        setIsBlacklisted(!!blacklist.length)
        const blockTypes = blacklist.map((blacklist: BlacklistEntry) => blacklist.blockType)
        if (blockTypes) {
          setBlockType(blockTypes[0])
        } else {
          setBlockType(undefined)
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const checkBlacklistType = (blockType: string): string => {
    switch (blockType) {
      case 'collector':
        return intl.formatMessage(pi['pi.beneficiary.blocked.collector'], {
          beneficiaryInfo: selectedBeneficiary?.title || selectedBeneficiary?.accountNumber,
        })
      case 'creditcard':
        return intl.formatMessage(pi['pi.beneficiary.blocked.creditcard'], {
          beneficiaryInfo: selectedBeneficiary?.title || selectedBeneficiary?.accountNumber,
        })
      case 'fodge':
        return intl.formatMessage(pi['pi.beneficiary.blocked.fodge'])
      case 'general':
        return intl.formatMessage(pi['pi.beneficiary.blocked.general'], {
          beneficiaryInfo: selectedBeneficiary?.title || selectedBeneficiary?.accountNumber,
        })
      default:
        return intl.formatMessage(pi['pi.beneficiary.blocked.general'], {
          beneficiaryInfo: selectedBeneficiary?.title || selectedBeneficiary?.accountNumber,
        })
    }
  }

  const getBankDays = async (cardNetworkPaymentDays: CardNetworkPaymentDays[]): Promise<void> => {
    const countryCodes = getCountryCodesForUserAndBeneficiary()
    const today = dayjs().format('YYYY-MM-DD')
    const uniquePaymentDays = cardNetworkPaymentDays.reduce((days: number[], v: CardNetworkPaymentDays) => {
      if (!days.includes(v.days)) {
        days.push(v.days)
      }
      return days
    }, [])

    await Promise.all(
      uniquePaymentDays.map((days: number) => {
        const cached = bankDaysGlobal.find((v) => v.days === days)

        return cached
          ? new Promise((r) => r({ bankDate: cached.bankDate, days, cached: true }))
          : gregApi
              .createBankDate({
                startDate: today,
                days: days,
                countries: countryCodes,
              })
              .then((bankDate: BankDate) => {
                const filteredBankDaysGlobal = bankDaysGlobal.filter((bd) => bd.days !== days)
                bankDaysGlobal = [...filteredBankDaysGlobal, { bankDate, days }]
                return { bankDate, days, cached: false }
              })
      })
    )

    const cardNetworkBankDays = cardNetworkPaymentDays.map(
      (paymentDays: CardNetworkPaymentDays): CardNetworkBankDay => {
        const foundBd = bankDaysGlobal.find((bd) => bd.days === paymentDays.days)
        if (foundBd && foundBd.bankDate) {
          return {
            cardNetwork: paymentDays.cardNetwork,
            bankDay: dayjs(foundBd.bankDate.bankDate),
          }
        } else {
          return {
            cardNetwork: paymentDays.cardNetwork,
            bankDay: dayjs(),
          }
        }
      }
    )

    const sortedCardNetworkBankDays = sortBy(cardNetworkBankDays, (day: CardNetworkBankDay) =>
      day.bankDay.format('YYYY-MM-DD')
    )
    setCardNetworksBankDays(sortedCardNetworkBankDays)
    formatAndSetCardNetworksBankDays(sortedCardNetworkBankDays)
  }

  const formatAndSetCardNetworksBankDays = (cardNetworksBankDays: CardNetworkBankDay[]): void => {
    const cardNetworksBankDaysWithSelectedDateDue = cardNetworksBankDays
      .filter(
        ({ cardNetwork }: CardNetworkBankDay) =>
          !selectedCard || cardNetwork === selectedCard?.typeProperties.cardNetwork
      )
      .map(({ cardNetwork, bankDay }: CardNetworkBankDay) => {
        const date = dateDue?.isAfter(bankDay) ? dateDue : bankDay
        return {
          cardNetwork,
          bankDay: date,
        }
      })

    const formattedCardNetworksBankDays = formatCardNetworksBankDays(cardNetworksBankDaysWithSelectedDateDue)
    setFormattedCardNetworksBankDays(formattedCardNetworksBankDays)
  }

  const getCountryCodesForUserAndBeneficiary = useCallback((): string[] => {
    const userCountryCode = user.user.countryCode

    const countryCodes = [userCountryCode]
    if (selectedBeneficiary) {
      const beneficiaryCountryCode = selectedBeneficiary.address.countryCode
      countryCodes.push(beneficiaryCountryCode)
    }

    return countryCodes
  }, [])

  const selectCard = (cardId?: string): void => {
    const card = cards.find((card: Source) => card.id === cardId)
    dispatchCurrentCardToStore(card)

    if (card) {
      const cardNetworkDateDue = getCardNetworkDateDue(cardNetworksBankDays, card)
      setDateDue(cardNetworkDateDue)
      setCurrentCardNetwork(card?.typeProperties.cardNetwork)

      Sentry.addBreadcrumb({
        category: 'sources',
        message: 'Selected saved card ' + card.id,
        level: 'info',
      })
    } else {
      setCurrentCardNetwork(undefined)
      setDateDue(undefined)
    }
  }

  const selectBeneficiary = async (beneficiaryId?: string): Promise<void> => {
    if (!beneficiaryId) {
      dispatchCurrentBeneficiaryToStore(undefined)
      return
    }

    const beneficiariesFromQuery = await getBeneficiaries()

    const beneficiary = beneficiariesFromQuery?.find((beneficiary: Beneficiary) => beneficiary.id === beneficiaryId)
    dispatchCurrentBeneficiaryToStore(beneficiary)
  }

  const createBeneficiary = async (beneficiary: Beneficiary): Promise<Beneficiary | undefined> => {
    try {
      const result = await beneficiariesApi.createBeneficiary(beneficiary)
      await setBeneficiariesState()
      if (beneficiary && currentCardNetwork) {
        const { routingNumber, accountNumber } = beneficiary
        void searchBlacklists(accountNumber, routingNumber)
      }
      return result
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: 'Error!',
        description: t('messages.error.beneficiary.save'),
        placement: 'topRight',
      })
    }
  }

  const getBankHolidays = async (countryCodes: string[]): Promise<void> => {
    try {
      const response = await Promise.all(
        uniq(countryCodes).map((countryCode: string): Promise<Holiday[]> => gregApi.getBankHolidays(countryCode))
      )
      const holidays = unionBy(flatten(response), (holiday: Holiday) => holiday.date)
      setBankHolidays(holidays)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleDateDueUpdate = (dateDue: dayjs.Dayjs): void => {
    setDateDue(dateDue)

    let date = ''
    if (initialFormDataKind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      date = paymentInstruction!.dateDue
    }

    if (initialFormDataKind === PAYMENT_KIND.TEMPLATE) {
      date = paymentInstructionTemplate!.dateDueNext
    }

    const isDateDueUpdated = !!date.length && date !== dateDue.format('YYYY-MM-DD')
    setIsDateDueUpdated(isDateDueUpdated)
  }

  const handleRecurrenceUpdate = (recurrence: Recurrence | undefined): void => {
    setRecurrenceData(recurrence)

    if (recurrenceData && paymentInstructionTemplate) {
      const isRecurrenceDataUpdated = !isEqual(recurrence, getRecurrenceDataFromTemplate(paymentInstructionTemplate))
      setIsRecurrenceDataUpdated(isRecurrenceDataUpdated)
    } else {
      setIsRecurrenceDataUpdated(true)
    }
  }

  const handleAttachmentUpdate = (attachment: Attachment | undefined): void => {
    setAttachment(attachment)

    if (initialFormData?.amount) {
      let isAttachmentInInitialData = false

      if (initialFormDataKind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
        isAttachmentInInitialData = !!paymentInstruction!.attachments?.length
      } else {
        isAttachmentInInitialData = !!paymentInstructionTemplate!.paymentInstructionProperties.attachments?.length
      }

      const isAttachmentUpdated = attachment !== undefined || (attachment === undefined && isAttachmentInInitialData)
      setIsAttachmentUpdated(isAttachmentUpdated)
    }
  }

  const updatePayment = async (
    values: PaymentFormData,
    profileId: string,
    date: dayjs.Dayjs,
    paymentKind: PAYMENT_KIND
  ): Promise<void> => {
    try {
      if (
        paymentKind === PAYMENT_KIND.PAYMENT_INSTRUCTION &&
        initialFormDataKind === PAYMENT_KIND.PAYMENT_INSTRUCTION
      ) {
        // PAYMENT INSTRUCTION UPDATE
        const payment = mapFormToPaymentInstruction(values, profileId, date)
        const result = await updatePaymentInstruction({
          ...paymentInstruction,
          ...payment,
        })

        const isAttachmentInInitialData = !!paymentInstruction!.attachments?.length

        if (attachment) {
          if (isAttachmentInInitialData && isAttachmentUpdated) {
            // remove the old, and create the new
            await deleteAttachment(result.id!, result.attachments![0].attachmentId!, PAYMENT_KIND.PAYMENT_INSTRUCTION)
            void createAttachment(result.id!, attachment, PAYMENT_KIND.PAYMENT_INSTRUCTION)
          }

          if (!isAttachmentInInitialData) {
            // create the new
            void createAttachment(result.id!, attachment, PAYMENT_KIND.PAYMENT_INSTRUCTION)
          }
        }
        // remove attachment
        if (!attachment && isAttachmentInInitialData) {
          await deleteAttachment(result.id!, result.attachments![0].attachmentId!, PAYMENT_KIND.PAYMENT_INSTRUCTION)
        }
      } else if (paymentKind === PAYMENT_KIND.PAYMENT_INSTRUCTION && initialFormDataKind === PAYMENT_KIND.TEMPLATE) {
        // REPLACE TEMPLATE WITH PAYMENT INSTRUCTION
        const payment = mapFormToPaymentInstruction(values, profileId, date)
        const result = await replaceTemplateWithPaymentInstruction(payment)

        const isAttachmentInInitialData = !!paymentInstructionTemplate?.paymentInstructionProperties.attachments?.length

        if (attachment) {
          // If template is replaced with the payment instruction and attachment is not updated, transfer attachment
          if (isAttachmentInInitialData && !isAttachmentUpdated) {
            const attachmentId = paymentInstructionTemplate.paymentInstructionProperties.attachments![0].attachmentId!
            await piApi.transfersAttachemntToPaymentInstruction(attachmentId, result.id!)
          }

          // create the new attachment
          if (!isAttachmentInInitialData || (isAttachmentInInitialData && isAttachmentUpdated)) {
            void createAttachment(result.id!, attachment, PAYMENT_KIND.PAYMENT_INSTRUCTION)
          }
        }
        // delete template after it's replaced
        deleteTemplate(paymentInstructionTemplate!.id!)
      } else if (paymentKind === PAYMENT_KIND.TEMPLATE && initialFormDataKind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
        // REPLACE PAYMENT INSTRUCTION WITH TEMPLATE
        const payment = mapFormToPaymentInstructionTemplate(
          values,
          profileId,
          recurrenceData || DEFAULT_RECURRENCE_DATA,
          date
        )
        const result = await replacePaymentInstructionWithTemplate(payment)

        const isAttachmentInInitialData = !!paymentInstruction?.attachments?.length

        if (attachment) {
          // If payment instructon is replaced with the template and attachment is not updated, transfer attachment
          if (isAttachmentInInitialData && !isAttachmentUpdated) {
            const attachmentId = paymentInstruction.attachments![0].attachmentId!
            await piApi.transfersAttachemntToTemplate(attachmentId, result.id!)
          }

          // create the new attachment
          if (!isAttachmentInInitialData || (isAttachmentInInitialData && isAttachmentUpdated)) {
            void createAttachment(result.id!, attachment, PAYMENT_KIND.TEMPLATE)
          }
        }
        // delete payment instruction after it's replaced
        deletePaymentInstruction(paymentInstruction!.id!)
      } else {
        // TEMPLATE UPDATE
        const payment = mapFormToPaymentInstructionTemplate(
          values,
          profileId,
          recurrenceData || DEFAULT_RECURRENCE_DATA,
          date,
          paymentInstructionTemplate!.paymentInstructionProperties
        )
        const result = await updatePaymentInstructionTemplate({
          ...paymentInstructionTemplate,
          ...payment,
        })

        const isAttachmentInInitialData = !!paymentInstructionTemplate!.paymentInstructionProperties.attachments?.length

        if (attachment) {
          if (isAttachmentInInitialData && isAttachmentUpdated) {
            // remove the old, and create the new
            await deleteAttachment(
              result.id!,
              result.paymentInstructionProperties.attachments![0].attachmentId!,
              PAYMENT_KIND.TEMPLATE
            )
            void createAttachment(result.id!, attachment, PAYMENT_KIND.TEMPLATE)
          }

          if (!isAttachmentInInitialData) {
            // create the new
            void createAttachment(result.id!, attachment, PAYMENT_KIND.TEMPLATE)
          }
        }
        // remove attachment
        if (!attachment && isAttachmentInInitialData) {
          await deleteAttachment(
            result.id!,
            result.paymentInstructionProperties.attachments![0].attachmentId!,
            PAYMENT_KIND.TEMPLATE
          )
        }
      }
      resetState()
      void message.success(intl.formatMessage(pi['pi.add.addPayments.form.payment.update.success']))
    } catch (error) {
      Sentry.captureException(error)
      const { details: { errorMessage = '' } = {} } = error as BillhopError
      if (errorMessage === 'BIN_BLOCKED') {
        setIsInWhitelist(false)
      }
      throw error
    }
  }

  const createPayment = async (
    values: PaymentFormData,
    profileId: string,
    date: dayjs.Dayjs,
    paymentKind: PAYMENT_KIND
  ): Promise<void> => {
    if (paymentKind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      const payment = mapFormToPaymentInstruction(values, profileId, date)

      try {
        const result = await createPaymentInstruction(payment)
        if (attachment) {
          void createAttachment(result.id!, attachment, PAYMENT_KIND.PAYMENT_INSTRUCTION)
        }
        if (result) {
          void message.success(intl.formatMessage(pi['pi.add.addPayments.form.card.payment.success']))
          resetState()
        }
      } catch (error) {
        Sentry.captureException(error)
        const { details: { errorMessage = '' } = {} } = error as BillhopError
        if (errorMessage === 'BIN_BLOCKED') {
          setIsInWhitelist(false)
        }
        throw error
      }
    } else {
      const payment = mapFormToPaymentInstructionTemplate(
        values,
        profileId,
        recurrenceData || DEFAULT_RECURRENCE_DATA,
        date
      )

      try {
        const result = await createPaymentInstructionTemplate(payment)
        if (attachment) {
          void createAttachment(result.id!, attachment, PAYMENT_KIND.TEMPLATE)
        }
        resetState()
      } catch (error) {
        Sentry.captureException(error)
        const { details: { errorMessage = '' } = {} } = error as BillhopError
        if (errorMessage === 'BIN_BLOCKED') {
          setIsInWhitelist(false)
        }
        throw error
      }
    }
  }

  const submitForm = async (values: PaymentFormData): Promise<void> => {
    const cardNetworkDateDue = getCardNetworkDateDue(cardNetworksBankDays, selectedCard)
    const isSelectedDateDueAfterCardNetworkDateDue = dateDue?.isAfter(cardNetworkDateDue)
    const date = isSelectedDateDueAfterCardNetworkDateDue ? dateDue : cardNetworkDateDue

    if (!date) {
      notifyError(t('messages.error.card_date.missing'))
      return
    }

    const paymentKind =
      values.recurring || isSelectedDateDueAfterCardNetworkDateDue
        ? PAYMENT_KIND.TEMPLATE
        : PAYMENT_KIND.PAYMENT_INSTRUCTION

    if (initialFormData?.amount) {
      await updatePayment(values, profileId, date, paymentKind)
    } else {
      await createPayment(values, profileId, date, paymentKind)
    }
  }

  const resetState = (): void => {
    const initialData: PaymentFormData = {
      recurring: false,
    }

    // set default card
    if (cards.length === 1) {
      const card = cards[0]
      // because mapped vcn pool have set id as accountNumber
      if (card && card.type === 'vcn' && card.typeProperties && card.typeProperties.accountNumber) {
        selectCard(card.typeProperties.accountNumber)
      } else {
        selectCard(card.id)
      }
    } else {
      selectCard()
    }

    setInitialFormData(initialData)
    dispatchCurrentBeneficiaryToStore(undefined)
    setInitialFormDataKind(undefined)
    dispatchCurrentAmountToStore(undefined)
    setDateDue(undefined)
    setRecurrenceData(undefined)
    setAttachment(undefined)
  }

  const handleCancelEdit = useCallback((): void => {
    cancelEdit()
    resetState()
  }, [])

  return (
    <PaymentForm
      bankHolidays={bankHolidays}
      cardNetworks={cardNetworks}
      currentCardNetwork={currentCardNetwork}
      cardNetworksBankDays={cardNetworksBankDays}
      currencies={currencies}
      dateDue={dateDue}
      formattedCardNetworksBankDays={formattedCardNetworksBankDays}
      gateways={gateways}
      initialFormData={initialFormData}
      isDateDueUpdated={isDateDueUpdated}
      isRecurrenceDataUpdated={isRecurrenceDataUpdated}
      isAttachmentUpdated={isAttachmentUpdated}
      paymentLimit={paymentLimit}
      recurrenceData={recurrenceData}
      attachment={attachment}
      blacklist={blacklists}
      blockType={blockType}
      selectCard={selectCard}
      selectBeneficiary={selectBeneficiary}
      createBeneficiary={createBeneficiary}
      setDateDue={handleDateDueUpdate}
      cancelEdit={handleCancelEdit}
      setRecurrenceData={handleRecurrenceUpdate}
      submitForm={submitForm}
      setAttachment={handleAttachmentUpdate}
      checkBlacklistType={checkBlacklistType}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      searchBlacklists={searchBlacklists}
      isBlacklisted={isBlacklisted}
      setIsBlacklisted={setIsBlacklisted}
      isInWhiteList={isInWhitelist}
      setIsInWhiteList={setIsInWhitelist}
    />
  )
}

export default CreatePayments
